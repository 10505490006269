import React, { createContext, useContext, useState, useMemo } from "react";

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [countChat, setCountChat] = useState(0);
  const [countOrder, setCountOrder] = useState(0);
  const [countRequest, setCountRequest] = useState(0);
  const [sound, setSound] = useState(true);

  let incChat = 0;
  let incOrder = 0;
  let incRequest = 0;

  const resetChat = () => {
    incChat = 0;
    setCountChat(0);
  };
  const resetOrder = () => {
    incOrder = 0;
    setCountOrder(0);
  };
  const resetRequest = () => {
    incRequest = 0;
    setCountRequest(0);
  };
  const addChat = () => {
    setCountChat(incChat++);
  };
  const addOrder = () => {
    setCountOrder(incOrder++);
  };
  const addRequest = () => {
    setCountRequest(incRequest++);
  };

  const toggleSound = () => {
    setSound(!sound);
  };

  const theValues = useMemo(
    () => ({
      resetChat,
      resetOrder,
      resetRequest,
      addChat,
      addOrder,
      addRequest,
      toggleSound,
      countChat,
      countOrder,
      countRequest,
      sound,
    }),
    [countChat, countOrder, countRequest, sound]
  );

  return (
    <NotificationContext.Provider value={theValues}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
