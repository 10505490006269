export const USER_KEY = "@fiveshotel/user";
export const USER_DATA_KEY = "@fiveshotel/user_data";
export const HOTEL_DATA = "@fiveshotel/hotel_data";
export const HOTEL_UUID = "@fiveshotel/hotel_uuid";
export const JWT_TOKEN = "@fiveshotel/jwt_token";
export const WHATSAPP = "whatsapp";
export const PHONE = "phone";
export const TODOS = -1;
export const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

export const TYPE_CHAT = "chat";
export const TYPE_ORDER = "order";
export const TYPE_REQUEST = "request";

export const TIER_LIGHT = "LIGHT";
export const TIER_MEDIUM = "MEDIUM";
export const TIER_PRO = "PRO";