import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import ArrowLeft from "@mui/icons-material/East";
import Check from "@mui/icons-material/CheckRounded";
import { numberToRealBR } from "../../utils/stringUtils";
import { sendPushNotification } from "../../api/notificationController";

import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { formatDateTimePtBr } from "../../utils/dateUtils";

import { HOTEL_UUID } from "../../utils/constants";
//import { getDatabase, ref, child, push, update } from "firebase/database";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const AddButton = styled(Button)({
  textTransform: "none",
  height: 48,
});

const styles = {
  cardContainer: {
    backgroundColor: "#E8EAEE",
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    width: 400,
    borderRadius: 8,
  },

  statusText: {
    color: "#fff",
    fontWeight: "bold",
    fontSize: 11,
  },

  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buttonContainer: {
    margin: 10,
  },

  confirmOrder: {
    fontSize: 14,
    fontWeight: "bold",
  },

  deliveryOrder: {
    fontSize: 14,
    fontWeight: "bold",
    backgroundColor: "#2DA771",
  },

  dateHour: {
    color: "#979797",
    fontSize: 18,
    marginBottom: 10,
  },

  priceHeader: {
    fontSize: 38,
    fontWeight: 900,
    letterSpacing: 1,
  },

  roomLabel: {
    color: "#979797",
    fontSize: 18,
    letterSpacing: 0.4,
    marginTop: 8,
  },

  roomValue: {
    color: "#4177F6",
    fontWeight: 800,
    fontSize: 18,
    letterSpacing: 0.4,
    marginTop: 8,
  },

  orderIdLabel: {
    fontSize: 14,
    fontWeight: 600,
    marginLeft: 8,
  },

  collapseButtonContent: {
    marginTop: -20,
  },

  collapseButton: {
    backgroundColor: "#9FA2B4",
    borderRadius: 8,
    color: "#fff",
    width: 30,
    height: 30,
  },

  productContent: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
  },

  productTitle: {
    fontWeight: 700,
    fontSize: 14,
  },

  productQuantity: {
    fontWeight: 500,
    fontSize: 12,
    opacity: 0.5,
    marginTop: -1,
  },

  obs: {
    fontWeight: 800,
    fontSize: 12,
    opacity: 0.5,
    marginTop: -30,
  },

  productPrice: {
    fontWeight: 700,
    fontSize: 14,
  },

  priceFooterLabel: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 15,
  },

  priceFooterValue: {
    fontWeight: 700,
    fontSize: 14,
    marginTop: 15,
    color: "#4177F6",
  },
};

function OrdersProgress(props) {
  const { order } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderStatus = () => {
    const status = order.status;

    let text = "";
    let color = "";

    if (status == "created") {
      text = "ABERTO";
      color = "#FF9C00";
    }

    if (status == "confirmed") {
      text = "NA COZINHA";
      color = "#4177F6";
    }

    if (status == "done") {
      text = "FINALIZADO";
      color = "#2DA771";
    }

    return (
      <div
        style={{
          marginBottom: 10,
          height: 26,
          paddingLeft: 20,
          paddingRight: 20,
          backgroundColor: color,
          borderRadius: 20,
        }}
      >
        <span style={styles.statusText}>{text}</span>
      </div>
    );
  };

  const changeorOrderStatus = () => {
    /*
    const db = getDatabase();

    const postData = {
      createdAt: order.createdAt,
      itens: order.itens,
      status: order.status == "created" ? "confirmed" : "done",
      room: order.room,
      user_name: order.user_name,
      local: order.local,
      total: order.total,
      order_id: order.order_id,
      hotel_uuid: order.hotel_uuid,
      user_uuid: order.user_uuid,
      device_token: order.device_token,
    };

    const updates = {};
    updates[
      `/orders/${localStorage.getItem(HOTEL_UUID)}/${order.user_uuid}/${
        order.order_id
      }`
    ] = postData;

    const title =
      order.status == "created" ? "Pedido confirmado" : "Pedido pronto";
    const desc =
      order.status == "created"
        ? "Seu pedido foi confirmado e está sendo preparado!"
        : "Seu pedido está pronto!";

    return update(ref(db), updates).then(async () => {
      await sendPushNotification(title, desc, order.user_uuid);
    });
    */
  };

  return (
    <Box maxWidth="xl" sx={{ mb: 5, ml: 1, mt: 3 }}>
      <Card style={styles.cardContainer}>
        <CardContent>
          <div style={styles.row}>
            <span style={styles.dateHour}>
              {formatDateTimePtBr(new Date(order.createdAt))}
            </span>
            {renderStatus()}
          </div>

          <span style={styles.priceHeader}>{numberToRealBR(order.total)}</span>

          <div style={styles.row}>
            <span style={styles.roomLabel}>
              Quarto: <span style={styles.roomValue}>{order.room}</span>
            </span>
            <span style={styles.roomLabel}>
              Criado por:{" "}
              <span style={styles.roomValue}>{order.user_name}</span>
            </span>
          </div>

          <Divider style={{ marginTop: 10 }} />
        </CardContent>
        <CardActions style={styles.collapseButtonContent}>
          <span style={styles.orderIdLabel}>Pedido: #{order.order_id}</span>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon style={styles.collapseButton} />
          </ExpandMore>
        </CardActions>

        {expanded && <Divider sx={{ ml: 2, mr: 2 }} />}

        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {order.itens.map((item, index) => {
              return (
                <>
                  <div style={styles.row}>
                    <div style={styles.productContent}>
                      <span style={styles.productTitle}>{item.title}</span>
                      <br />
                      <span style={styles.productQuantity}>
                        Quantidade: {item.quantity}
                      </span>
                      <br />
                      {item.obs && (
                        <span style={styles.obs}>Obs: {item.obs}</span>
                      )}
                    </div>

                    <span style={styles.productPrice}>
                      {numberToRealBR(item.price)}
                    </span>
                  </div>
                  <Divider />
                </>
              );
            })}

            <div style={styles.row}>
              <span style={styles.priceFooterLabel}>Valor final</span>
              <span style={styles.priceFooterValue}>
                {numberToRealBR(order.total)}
              </span>
            </div>
          </CardContent>
        </Collapse>

        <Divider sx={{ ml: 2, mr: 2, mt: -1 }} />

        <div style={styles.buttonContainer}>
          {order.status == "created" && (
            <AddButton
              variant="contained"
              color="primary"
              onClick={changeorOrderStatus}
              startIcon={<ArrowLeft />}
              style={styles.confirmOrder}
            >
              Confirmar pedido
            </AddButton>
          )}

          {order.status == "confirmed" && (
            <AddButton
              variant="contained"
              onClick={changeorOrderStatus}
              startIcon={<Check />}
              style={styles.deliveryOrder}
            >
              Pronto para entrega
            </AddButton>
          )}
        </div>
      </Card>
    </Box>
  );
}

export default OrdersProgress;
