import * as React from 'react';
import { Button } from '@mui/material';

function StatusRequest(props) {
  return (
    <Button
      style={{
        height: '24px',
        borderRadius: '100px',
        background: colorStatus(props.status)
      }}
    >
      <span
        style={{
          color: '#FFFFFF',
          textTransform: 'capitalize'
        }}
      >{translateStatus(props.status)}</span>
    </Button>
  );
}

function translateStatus(status) {
  switch(status) {
    case 'done':
      return 'Finalizado'
    case 'inprogress':
      return 'Andamento'
    default:
      return 'Aberto'
  }
}

function colorStatus(status) {
  switch(status) {
    case 'done':
      return '#2DA771'
    case 'inprogress':
      return '#E58C00'
    default:
      return '#4177F6'
  }
}

export default StatusRequest;