import axios from 'axios';

export function getServices(hotel_uuid) {
  return axios.get(`/api/v1/services/${hotel_uuid}`)
}

export function createUpdateService(hotel_uuid, payload) {
  return axios.post(`/api/v1/services/${hotel_uuid}`,
    payload
  )
}

export function deleteService(hotel_uuid, activity_id) {
  return axios.delete(`/api/v1/services/${hotel_uuid}/${activity_id}`)
}