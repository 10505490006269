import axios from "axios";

export function getAllRatingCurrentYear(hotel_uuid) {
  return axios.get(`/api/v1/rate/${hotel_uuid}`);
}

export function paginationRatings(hotel_uuid, lastDoc, rowsPerPage, next) {
  return axios.post(`/api/v1/rate/${hotel_uuid}/pagination`, {
    item: lastDoc,
    rows_per_page: rowsPerPage,
    next: next,
  });
}

export function getGoogleRatings(hotel_uuid) {
  return axios.get(`/api/v1/rate/${hotel_uuid}/google`);
}

export async function getTripAdvisorRatings(hotel_uuid) {
  return axios.get(`/api/v1/tripadvisorRate/${hotel_uuid}`)
}
