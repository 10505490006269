import React, { useState } from 'react';
import { SwipeableDrawer, Divider, Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import { formatDateTimePtBr } from '../../utils/dateUtils'
import { numberToRealBR } from '../../utils/stringUtils'
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import Star from '@mui/icons-material/Star';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const styles = {
  cardContainer: {
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
  },

  title: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    color: '#252733',
    letterSpacing: '0.4px',
    lineHeight: '24px'
  },

  statusText: {
    color: '#fff',
    fontWeight: 'bold',
    fontSize: 11,
  },

  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },

  row: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "center",
  },

  header: {
    display: 'flex',
    alignItems: "center",
    padding: 10
  },

  buttonContainer: {
    margin: 10
  },

  confirmOrder: {
    fontSize: 14,
    fontWeight: 'bold'
  },

  deliveryOrder: {
    fontSize: 14,
    fontWeight: 'bold',
    backgroundColor: '#2DA771'
  },

  dateHour: {
    color: '#979797',
    fontSize: 18,
    marginBottom: 10
  },

  priceHeader: {
    fontSize: 32,
    fontWeight: 900,
    letterSpacing: 1,
  },

  roomLabel: {
    color: '#979797',
    fontSize: 18,
    letterSpacing: 0.4,
    marginTop: 8
  },

  roomValue: {
    color: '#4177F6',
    fontWeight: 800,
    fontSize: 18,
    letterSpacing: 0.4,
    marginTop: 8
  },

  orderIdLabel: {
    fontSize: 16,
    fontWeight: 600,
    marginLeft: 8,
  },

  collapseButtonContent: {
    marginTop: -20
  },

  collapseButton: {
    backgroundColor: '#9FA2B4',
    borderRadius: 8,
    color: '#fff',
    width: 30,
    height: 30
  },

  productContent: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 5
  },

  productTitle: {
    fontWeight: 700,
    fontSize: 14,
  },

  productQuantity: {
    fontWeight: 500,
    fontSize: 12,
    opacity: 0.5,
    marginTop: -1
  },

  obs: {
    fontWeight: 800,
    fontSize: 12,
    opacity: 0.5,
    marginTop: -30
  },

  productPrice: {
    fontWeight: 700,
    fontSize: 14
  },

  rateContainer: {
    marginTop: 10,
  }
}

const drawerWidth = 450;

function DrawerRequest(props) {
  const { order } = props

  const [open, setOpen] = useState(true);

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const renderStatus = () => {

    const status = order.status

    let text = ''
    let color = ''

    if (status == 'created') {
      text = 'ABERTO'
      color = '#FF9C00'
    }

    if (status == 'confirmed') {
      text = 'NA COZINHA'
      color = '#4177F6'
    }

    if (status == 'done') {
      text = 'FINALIZADO'
      color = '#2DA771'
    }

    return (
      <div style={{ marginBottom: 10, height: 26, paddingLeft: 20, paddingRight: 20, backgroundColor: color, borderRadius: 20 }}>
        <span style={styles.statusText}>{text}</span>
      </div>
    )
  };

  const renderRate = () => {
    const stars = []

    for (let index = 0; index < order.rating; index++) {
      stars.push(<Star style={{ color: '#4177F6', width: 40, height: 40, marginTop: 5 }} />)
    }

    while (stars.length < 5) {
      stars.push(<Star style={{ color: '#C4C4C4', width: 40, height: 40, marginTop: 5 }} />)
    }

    return stars
  }


  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    props.close()
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: '1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >

      <div style={styles.header}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Pedido</span>
      </div>

      <Divider />

      <Box maxWidth="xl" sx={{ mb: 5, ml: 1, mt: 3 }}>

        <div style={styles.cardContainer}>

          <span style={styles.orderIdLabel}>
            Pedido: #{order.order_id}
          </span>

          <Divider sx={{ mt: 2, mr: 2 }} />

          <CardContent>

            <div style={styles.row}>
              <span style={styles.dateHour}>{formatDateTimePtBr(new Date(order.createdAt))}</span>
              {renderStatus()}
            </div>

            <span style={styles.priceHeader}>{numberToRealBR(order.total)}</span>

            <div style={styles.row}>
              <span style={styles.roomLabel}>Quarto: <span style={styles.roomValue}>{order.room}</span></span>
              <span style={styles.roomLabel}>Criado por: <span style={styles.roomValue}>{order.user_name}</span></span>
            </div>

          </CardContent>

          <Divider sx={{ ml: 2, mr: 2 }} />

          <CardContent>
            {order.itens.map((item, index) => {
              return (
                <>
                  <div style={styles.row}>
                    <div style={styles.productContent}>
                      <span style={styles.productTitle}>{item.title}</span><br />
                      <span style={styles.productQuantity}>Quantidade: {item.quantity}</span><br />
                      {item.obs &&
                        <span style={styles.obs}>Obs: {item.obs}</span>
                      }

                    </div>

                    <span style={styles.productPrice}>{numberToRealBR(item.price)}</span>
                  </div>
                  <Divider sx={{ mt: 2 }} />
                </>
              )
            })}

            <div style={styles.rateContainer}>
              <span style={styles.productTitle}>Avaliação: {order.rating ? order.rating : 'Não avaliado'}</span><br />

              {order.rating &&
                renderRate()
              }

            </div>
          </CardContent>
        </div>

      </Box>

    </SwipeableDrawer>
  )
}

export default DrawerRequest;
