import React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardCounter from "../../components/CardCounter";
import TabNav from "../../components/TabNav";
import Title from "../../components/Title";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Copyright from "../../components/Copyright";

const GoogleIcon = require("../../assets/google.png");
const TripAdvisorIcon = require("../../assets/tripadvisor.png");

export default function DashboardScreen({
  hotel,
  countActiveGuests,
  countRatings,
  countOpenNotifications,
  loading,
  googleRating,
  tripAdvisorRating,
}) {
  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Toolbar />
      <Container maxWidth="xl" sx={{ mt: 5, mb: 5 }}>
        <Title text="Dashboard" icon={<DashboardIcon color="primary" />} />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={2.4}>
            <CardCounter
              count={googleRating ? googleRating : "Buscando..."}
              // label="Média no Google"
              compare="/5"
              loading={loading}
              icon={
                <img
                src={GoogleIcon}
                height={32}
                width={32}
                alt="Google Icon"
                />
                }
                />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <CardCounter
              count={tripAdvisorRating ? tripAdvisorRating : "Buscando..."}
              // label="Média no TripAdvisor"
              compare="/5"
              loading={loading}
              icon={
                <img
                src={TripAdvisorIcon}
                height={30}
                width={30}
                alt="TripAdvisor Icon"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <CardCounter
              count={hotel ? hotel.avgNoteNPS : ""}
              label="NPS"
              compare="/10"
              loading={loading}
              />
          </Grid>

          <Grid item xs={12} sm={6} md={2.4}>
            <CardCounter
              count={countActiveGuests}
              label="Hóspedes ativos"
              loading={loading}
              />
          </Grid>
          <Grid item xs={12} sm={6} md={2.4}>
            <CardCounter
              count={countRatings}
              label="Avaliações"
              loading={loading}
              />
          </Grid>

              
          {hotel ? (
            <TabNav
              hotel={hotel}
              countOpenNotifications={countOpenNotifications}
            />
          ) : null}
        </Grid>
        <Copyright />
      </Container>
    </Box>
  );
}
