import axios from 'axios';

// TODO: criar funcao para recuperar senha
// logout

export function login(user, password) {
  return axios.post('/api/v1/user/login', { user: user, password: password })
}

export function getUserData() {
  return axios.get('/api/v1/user')
}

export function getUserById(user_uuid) {
  return axios.get(`/api/v1/user/${user_uuid}`)
}

export function sendForgotPassword(email) {
  return axios.post('/api/v1/user/forgot-password', { email: email })
}