import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loading from "../../components/Loading";
import EditIcon from "@mui/icons-material/Edit";
import ButtonStyle from "../../styles/button.style";
import { Stack } from "@mui/material";
const DANCE = require('../../assets/dance.png')
const GAME = require('../../assets/game.png')
const FOOD = require('../../assets/food.png')
const RELAX = require('../../assets/relax.png')

const columns = [
  { id: "name", label: "Nome", minWidth: 300 },
  { id: "description", label: "Descrição", minWidth: 550, maxWidth: 550 },
  { id: "type", label: "Tipo", minWidth: 150 },
  { id: "edit", label: "", minWidth: 10 },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

const renderType = (type) => {
  // <MenuItem value={'COFFEE'}>Café/Lanche</MenuItem>
  // <MenuItem value={'FOOD'}>Alimentação</MenuItem>
  // <MenuItem value={'POOL'}>Intreterimento</MenuItem>
  // <MenuItem value={''}>Outros</MenuItem>

  if (type && type == 'COFFEE') {
    return 'Café'
  }
  if (type && type == 'CHECKIN') {
    return 'Check-in'
  }
  if (type && type == 'CHECKOUT') {
    return 'Check-out'
  }
  if (type && type == 'FOOD') {
    return 'Alimentação'
  }
  if (type && type == 'POOL') {
    return 'Entreterimento'
  }
  if (type == '' || !type) {
    return 'Outros'
  }
};

const GridActivities = (props) => {

  return (
    <Grid container spacing={3} style={{ marginTop: 20 }}>
      <Grid item xs={12}>

        {props.loading &&
          <Loading color={"#4177F6"} text={"Carregando..."} />
        }

        {!props.loading && props.rows.length > 0 &&
          // props.rows.map((atividade, index) => {
          //   return (
          <Grid sx={{ mt: 5 }}>

            <Paper sx={{ width: "100%" }}>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: "#F5F6FA",
                            color: "#9FA2B4",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={index}
                        >
                          <TableCell style={styleRow}>
                            {row.title}
                          </TableCell>
                          <TableCell style={styleRow}>
                            {row.description}
                          </TableCell>
                          <TableCell style={styleRow}>
                            {renderType(row.type)}
                          </TableCell>
                          <TableCell align="right" style={styleRow}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Button
                                variant="contained"
                                style={ButtonStyle.default}
                                elevation={0}
                                onClick={() => {
                                  props.openEdit(row);
                                }}
                              >
                                <EditIcon title="Alterar" />
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>
          //   )
          // })
        }
      </Grid>
    </Grid>
  );
};

export default GridActivities;
