const ButtonStyle = {
  default: {
    textTransform: 'none',
    backgroundColor: '#ECF1FE',
    color: '#4177F6',
    boxShadow: '0px 0px 0px 0px',
    fontSize: 14,
    fontWeight: 'bold',
    height: 48,
    borderRadius: 6
  },
  primary: {
    textTransform: 'none',
    backgroundColor: '#4177F6',
    color: '#FFFFFF',
    boxShadow: '0px 0px 0px 0px',
    fontFamily: 'Poppins',
    fontSize: 14,
    fontWeight: 700,
    borderRadius: 6,
    marginTop: 20
  },
  uploadPhoto: {
    textTransform: 'none',
    backgroundColor: '#CAD9FD',
    color: '#4177F6',
    boxShadow: '0px 0px 0px 0px'
  },
  defaultNoBorder: {
    textTransform: 'none',
    backgroundColor: '#ECF1FE',
    color: '#4177F6',
    boxShadow: '0px 0px 0px 0px',
    border: '0px'
  },
  buttonGray: {
    textTransform: 'none',
    backgroundColor: '#EEEEEE',
    color: '#CCCCCC',
    boxShadow: '0px 0px 0px 0px',
    border: '0px'
  },
}

export default ButtonStyle