import React, { useState } from 'react';
import TabItemNotification from './TabItemNotifications';
import { Box, Grid } from '@mui/material';
import DrawerNotifications from '../../components/notifications/DrawerNotifications';

function TabNotifications(props) {

  const [reloading, setReloading] = useState(false);
  const [openNotification, setOpenNotification] = React.useState(false);

  const reload = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 1000);
  }

  const showNotification = () => {
    setOpenNotification(true);
  }

  const closeNotification = () => {
    setOpenNotification(false);
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {reloading
        ?
        <span
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            fontWeight: '600',
            fontSizqe: '19px',
            lineHeight: '28px',
            letterSpacing: '0.4px',
            color: '#252733',
            opacity: '0.5'
          }}
        > Carregando ... </span>
        :
        <Grid style={{ padding: 1 }} >
          <TabItemNotification hotel={props.hotel} reload={reload} countOpenNotifications={props.countOpenNotifications}/>
        </Grid>
      }

      {openNotification ? <DrawerNotifications close={closeNotification} /> : null}

    </Box>
  )
}

export default TabNotifications;