import React, { useState } from 'react';
import { SwipeableDrawer, IconButton, TextField, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { addTicket } from '../../api/ticketController';
import { useHotel } from '../../contexts/HotelProvider';
import { USER_DATA_KEY } from '../../utils/constants';

const styles = {
  title: {
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  subTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1px',
    color: '#252733',
    opacity: '0.5'
  },
  textSuggestion: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    opacity: '0.5'
  },
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px'
  }
}

const drawerWidth = 450;

function DrawerNotifications(props) {
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState(props['title'] || '');
  const [description, setDescription] = useState(props['description'] || '');
  const { hotelUID } = useHotel()

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    props.close()
  };

  const save = () => {
    const userData = localStorage.getItem(USER_DATA_KEY);
    if (userData) {
      const userDataParsed = JSON.parse(userData);

    const ticket = {
      title,
      description,
      create_by: userDataParsed.name,
      firebase_uuid: userDataParsed.firebase_uuid
    }

    addTicket(hotelUID, ticket)
      .then(() => {
        toast.success("Notificação criada com sucesso")
      })
      .catch(() => {
        toast.error("Erro ao criar o notificação, tente novamente mais tarde!")
      })        
    }
    props.close()
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    save()
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: '1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Criar notificação</span>
      </div>
      <Divider />

      <form onSubmit={handleSubmit}>
        <div style={styles.box}>
          <TextField
            id="outlined-basic"
            label="Título da notificação"
            variant="outlined"
            required
            style={styles.itemBox}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Descrição da notificação"
            variant="outlined"
            required
            multiline
            rows={4}
            placeholder="Descreva sua notificação aqui"
            style={styles.itemBox}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            inputProps={{
              maxLength: 80,
            }}
          />

          <Button
            variant='contained'
            elevation={0}
            style={{ marginTop: '20px' }}
            type="submit"
          >
            Criar Notificação
          </Button>
        </div>
      </form>
    </SwipeableDrawer>
  )
}

export default DrawerNotifications;