import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs, Button } from '@mui/material';
import Notifications from '@mui/icons-material/Notifications';
import { styled } from '@mui/material/styles';
import TabGeral from './TabGeral';
import TabRatings from './TabRatings';
import TabNotifications from '../screens/notifications/TabNotifications';
import { BrowserView, isTablet, isMobile } from 'react-device-detect';

import DrawerNotifications from './notifications/DrawerNotifications';
import WebPush from './WebPush';

const NotificationButton = styled(Button)({
  textTransform: 'none'
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabNav(props) {
  const [value, setValue] = React.useState(0);
  const [openNotification, setOpenNotification] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const showNotification = () => {
    setOpenNotification(true);
  }

  const closeNotification = () => {
    setOpenNotification(false);
  }

  return (
    <Box sx={{ width: '100%', marginTop: 5 }}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        aria-label="primary tabs example"
        style={{
          borderBottom: '1px solid #DFE0EB',
          marginLeft: 30
        }}
      >
        <Tab label='Visão geral' {...a11yProps(0)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 200, marginRight: 50 }} />
        <Tab label="Avaliações" {...a11yProps(1)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 200 }} />
        <Tab label="Notificações" {...a11yProps(2)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 200, marginLeft: 50 }} />

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <NotificationButton
            variant="contained"
            color="primary"
            startIcon={<Notifications />}
            onClick={showNotification}
            style={{ marginBottom: 10, fontWeight: 'bold', fontSize: 16, height: 48, width: 180, borderRadius: 4 }}
          >
            Criar notificação
          </NotificationButton>
        </Box>
      </Tabs>

      <TabPanel style={{ marginRight: -20 }} value={value} index={0}>
        <TabGeral hotel={props.hotel} />
      </TabPanel>
      <TabPanel style={{ marginRight: -20 }} value={value} index={1}>
        <TabRatings hotel={props.hotel} />
      </TabPanel>
      <TabPanel style={{ marginRight: -40 }} value={value} index={2}>
        <TabNotifications hotel={props.hotel} countOpenNotifications={props.countOpenNotifications} />
      </TabPanel>

      { !isTablet && !isMobile &&
        <WebPush />
      }

      {openNotification ? <DrawerNotifications close={closeNotification} /> : null}
    </Box>
  );
}