import React, { useState } from "react";
import {
  SwipeableDrawer,
  IconButton,
  TextField,
  Divider,
  MenuItem,
  Select,
  Checkbox,
  TableCell,
  TableBody,
  TableRow,
  TableHead,
  Table,
  Button,
  TableContainer,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../components/SubTitle";
import { HOTEL_UUID, PHONE, WHATSAPP } from "../../utils/constants";
import { toast } from "react-toastify";
import ButtonLoading from "../../components/layout/ButtonLoading";
import {
  createUpdatePartners,
  deletePartners,
} from "../../api/partnersController";
import InputFileImage from "../../components/inputfile/InputFileImage";
import TableStyle from "../../styles/table.style";
import { PatternFormat } from "react-number-format";
import CheckIcon from "@mui/icons-material/Check";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import DialogConfirm from "../../components/DialogConfirm";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#252733",
    letterSpacing: "0.4px",
    lineHeight: "24px",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowList: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
    marginLeft: "-24px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  boxTablePhones: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "30px",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },

  data: {
    fontSize: 14,
    fontWeight: 700,
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
  boxAddPhone: {
    padding: "20px",
    width: "100%",
    background: "#EEF1F5",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
  },
};

const drawerWidth = 600;

function DrawerPartners(props) {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState(props.name || "");
  const [site, setSite] = useState(props.site || "");
  const [type, setType] = useState(props.type || "");
  const [icon, setIcon] = useState(props["icon"] || "");
  const [image, setImage] = useState(props["image"] || "");
  const [principal, setPrincipal] = useState(props.principal || false);
  const [description, setDescription] = useState(props.description || "");
  const [phones, setPhones] = useState(props["phones"] || []);
  const [number, setNumber] = useState("");

  const [partnerId, setPartnerId] = useState(props.id || null);

  const [file, setFile] = useState(null);
  const [fileImage, setFileImage] = useState(null);

  const [loadingButton, setLoadingButton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const save = async () => {
    setLoadingButton(true);

    const form = new FormData();
    form.append("name", name);
    form.append("description", description);
    form.append("site", site);
    form.append("file", file);
    form.append("icon", icon);
    form.append("file_image", fileImage);
    form.append("image", image);
    form.append("type", type);
    form.append("phones", JSON.stringify(phones));
    form.append("principal", principal);

    await createUpdatePartners(
      localStorage.getItem(HOTEL_UUID),
      form,
      partnerId
    )
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
        setLoadingButton(false);
      })
      .catch((error) => {
        setLoadingButton(false);
        toast.error(error.response.data.message);
      });
  };

  const deleteItem = (responseOk) => {
    setOpenDelete(false);
    if (!responseOk) {
      return;
    }

    setLoadingButton(true);

    deletePartners(localStorage.getItem(HOTEL_UUID), partnerId)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
      })
      .catch(() => {
        toast.error("Erro, tente novamente mais tarde!");
        setLoadingButton(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
  };

  const checkWhatsapp = (e, index) => {
    phones[index].type = e.target.checked ? WHATSAPP : PHONE;
    setPhones([...phones]);
  };

  const addPhones = (e) => {
    if (!number) {
      return;
    }

    e.preventDefault();
    phones.push({
      number: number,
      type: PHONE,
    });
    setNumber("");
    setPhones([...phones]);
  };

  const delPhones = (index) => {
    phones.splice(index, 1);
    setPhones([...phones]);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.name}>Adicionar parceiro</span>
      </div>

      <Divider />

      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <SubTitle text="Logo" variant="subtitle2" />
        <InputFileImage setIcon={setIcon} icon={icon} setFile={setFile} />
      </div>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div style={styles.box}>
          <SubTitle text="Informações do parceiro" variant="subtitle2" />
          <div>
            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              placeholder="Nome do parceiro"
              required
              style={styles.itemBox}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              placeholder="Descrição do parceiro"
              required
              multiline
              rows={4}
              style={styles.itemBox}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Site"
              variant="outlined"
              placeholder="Site do parceiro"
              required
              style={styles.itemBox}
              value={site}
              onChange={(e) => setSite(e.target.value)}
            />

            <div style={styles.dateContainer}>
              <FormControl fullWidth>
                <InputLabel id="select-label">Tipo</InputLabel>
                <Select
                  labelId="select-label"
                  sx={{ width: "100%" }}
                  value={type}
                  onChange={(type) => setType(type.target.value)}
                  label="Tipo"
                >
                  <MenuItem value={"FOOD"}>Alimentação</MenuItem>
                  <MenuItem value={"TOUR"}>Passeio</MenuItem>
                  <MenuItem value={"OUTROS"}>Outros</MenuItem>
                </Select>
              </FormControl>
            </div>

            <div style={styles.rowList}>
              <div style={styles.row}>
              <FormGroup>
                <FormControlLabel control={
                  <Checkbox
                    checked={principal}
                    onChange={() => setPrincipal(!principal)}
                  />} 
                  label="Definir parceiro como destaque"
                  disabled={!principal && props.countPrincipalPartners >= 5}
                />
              </FormGroup>
              </div>

              <div style={{ opacity: 0.5 }}>
                <SubTitle
                  text={`${props.countPrincipalPartners}/5 disponíveis`}
                />
              </div>
            </div>

            {principal && (
              <div style={{ marginBottom: "20px" }}>
                <InputFileImage
                  setIcon={setImage}
                  icon={image}
                  setFile={setFileImage}
                />
              </div>
            )}

            <Divider />
            
            <div style={styles.rowBetween}>
              <SubTitle text="Contatos" variant="subtitle2" />
              <div style={{ opacity: 0.5}}>
                <SubTitle
                  text={`${phones.length}/2 disponíveis`}
                />
              </div>
            </div>

            <div style={styles.boxAddPhone}>
              <PatternFormat
                id="outlined-basic"
                sx={{ width: "100%" }}
                customInput={TextField}
                label="Número de Telefone"
                variant="outlined"
                placeholder="(xx) xxxxx-xxxx"
                format="(##) #####-####"
                value={number}
                onChange={(e) => setNumber(e.target.value)}
              />

              <Button
                type="submit"
                variant="contained"
                color="primary"
                elevation={0}
                startIcon={<CheckIcon />}
                fullWidth
                sx={{ marginTop: "15px" }}
                onClick={addPhones}
                disabled={phones.length >= 2}
              >
                Confirmar
              </Button>
            </div>

            <form onSubmit={handleSubmit} name="form-phones">
              <div style={styles.boxTablePhones}>
                <SubTitle
                  text="Editar números de telefones"
                  variant="subtitle2"
                />
                <TableContainer sx={{ maxHeight: 440, marginTop: "10px" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left" style={TableStyle.head}>
                          Número
                        </TableCell>
                        <TableCell align="left" style={TableStyle.head}>
                          Whatsapp
                        </TableCell>
                        <TableCell align="left" style={TableStyle.head}>
                          Edição
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {phones.map((row, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell style={TableStyle.textRow}>
                              {row.number}
                            </TableCell>
                            <TableCell
                              style={TableStyle.textRow}
                              align="center"
                            >
                              <Checkbox
                                checked={row.type === WHATSAPP}
                                onChange={(e) => checkWhatsapp(e, index)}
                              />
                            </TableCell>
                            <TableCell
                              style={TableStyle.textRow}
                              align="center"
                            >
                              <CloseIcon
                                color="primary"
                                sx={{
                                  background: "#E8EAEE",
                                  borderRadius: "2px",
                                }}
                                onClick={() => delPhones(index)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </form>

            <div style={{ marginTop: "24px" }}>
              <ButtonLoading label="Salvar" loading={loadingButton} />
            </div>

            {props.id ? (
              <ButtonLoading
                label="Excluir"
                loading={loadingButton}
                onClick={() => setOpenDelete(true)}
                delete
              />
            ) : null}
          </div>
        </div>
      </form>
      {openDelete && (
        <DialogConfirm
          onResponse={deleteItem}
          entity="esse parceiro"
        />
      )}
    </SwipeableDrawer>
  );
}

export default DrawerPartners;
