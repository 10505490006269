import React, { useState, useEffect } from "react";
import { HOTEL_UUID } from "../../utils/constants";
import SchedulerScreen from "./Scheduler.screen";

export default function SchedulerContainer() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  return <SchedulerScreen loading={loading} />;
}
