import React from "react";
import { Box, Skeleton } from "@mui/material";

function CardCounter(props) {
  const styles = {
    title: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '40px',
      lineHeight: '60px',
      textAlign: 'center',
      letterSpacing: '1px',
      color: '#4177F6'
    },
    subtitle: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '19px',
      lineHeight: '28px',
      textAlign: 'center',
      letterSpacing: '0.4px',
      color: '#979797',
      paddingRight: '5px'
    }
  }
  return (
    props.loading
      ?
      <Skeleton variant="rect" width={260} height={134} sx={{mt: 2, borderRadius: '4px', backgroundColor: '#E8EAEE'}} />
      :
      <Box
        component="p"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          border: "1px grey",
          borderRadius: "4px",
          backgroundColor: "#E8EAEE",
          height: "134px",
        }}
      >
        <div>
          <span style={styles.title}>{props.count}</span>
          {props.compare && <span style={styles.subtitle}>{props.compare}</span>}
        </div>

        <div style={{ alignItems: 'center', justifyContent: 'cneter', display: 'flex' }}>
          <span style={styles.subtitle}>{props.label}</span>
          {props.icon && props.icon}
        </div>
      </Box>
  );
};

export default CardCounter;