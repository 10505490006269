import React, { useEffect, useState } from "react";
import { SwipeableDrawer, IconButton, Divider, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ButtonStyle from "../../styles/button.style";
import SubTitle from "../../components/SubTitle";
import DatePicker from "react-datepicker";
import { getAvailabilityScheduler } from "../../api/schedulerController";
import GridSchedulerAvailable from "./GridSchedulerAvailable";
import { formatDateEn } from "../../utils/dateUtils";

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#252733",
    letterSpacing: "0.4px",
    lineHeight: "24px",
  },
  local: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
  },
  date: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
    opacity: "0.54",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },

  data: {
    fontSize: 14,
    fontWeight: 700,
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
  },
};

const drawerWidth = 600;

function DrawerSchedulerAvailable(props) {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  useEffect(() => {
    loadSchedulerAvailable(new Date());
  }, []);

  const onChange = (date) => {
    setSelectedDate(date);
    loadSchedulerAvailable(date);
  };

  const loadSchedulerAvailable = (date) => {
    setLoading(true);
    getAvailabilityScheduler(props.id, date)
      .then((resp) => {
        if (resp.data.availability && resp.data.availability.length > 0) {
          setRows(resp.data.availability[0].hours);
        } else {
          setRows([]);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally((e) => {
        setLoading(false);
      });
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>{props.name}</span>
      </div>
      <Divider />

      <div style={styles.box}>
        <div style={styles.boxAddPhone}>
          <SubTitle
            text="Selecione uma data para consulta a disponibilidade"
            variant="subtitle2"
          />
          <div style={styles.dateContainer}>
            <DatePicker
              selected={selectedDate}
              onChange={onChange}
              minDate={new Date()}
              inline
            />
          </div>

          <SubTitle text="Disponibilidade" variant="subtitle2" />

          <GridSchedulerAvailable rows={rows} loading={loading} />
        </div>
      </div>
    </SwipeableDrawer>
  );
}

export default DrawerSchedulerAvailable;
