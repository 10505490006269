import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { getHotel } from "../api/hotelController";
import { HOTEL_DATA, HOTEL_UUID } from "../utils/constants";

export const HotelContext = createContext({});

export const HotelProvider = ({ children }) => {
  const [hotel, setHotel] = useState(null);
  const [loadingHotel, setLoadingHotel] = useState(false);
  let count = 0;

  async function changeHotel(hotel_uuid) {
    await getHotelData(hotel_uuid);
  }

  async function getHotelData(paramHotelUID = null) {
    if(loadingHotel) {
      return
    }

    if(count > 0) {
      return
    }
    count = count + 1
    const hotel_uuid = paramHotelUID || localStorage.getItem(HOTEL_UUID);
    if (hotel_uuid) {
      if (hotel == null || hotel.hotel_uuid != hotel_uuid) {
        setLoadingHotel(true);
        console.log("getHotelData ...... ", hotel_uuid, "count: ", count, " hotel ", hotel);
        const response = await getHotel(hotel_uuid);
        setHotel(response.data);

        localStorage.setItem(HOTEL_DATA, JSON.stringify(response.data));
        localStorage.setItem(HOTEL_UUID, hotel_uuid);
        setLoadingHotel(false);
        count = 0
        return response.data;
      }
    }
    return hotel;
  }

  const theValues = useMemo(() => ({
    hotel,
    loadingHotel,
    setHotel,
    getHotelData,
    changeHotel,
    setLoadingHotel
  }));

  return (
    <HotelContext.Provider value={theValues}>{children}</HotelContext.Provider>
  );
};

export const useHotel = () => {
  return useContext(HotelContext);
};
