import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Copyright from '../components/Copyright';
const dog = require('../assets/ops-dog.png')

export default function PrivcacyPolice() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/fives.png"}
          height={56}
          width={149}
          alt="Fives"
          style={{ marginTop: 100 }}
        />

        <h1>Política de Privacidade - Fives App</h1>

        <p>A Fives App respeita a privacidade dos seus clientes e de todos os usuários da plataforma Fives. Esta Política de Privacidade explica como coletamos, usamos e protegemos as informações pessoais dos hotéis que utilizam nosso serviço. Ao utilizar o Fives, você concorda com as práticas descritas nesta Política de Privacidade.</p>

        <h2>1. Dados Coletados</h2>
        <p>Para fornecer nossos serviços, coletamos os seguintes dados dos hotéis:</p>
        <ul>
          <li><strong>Nome</strong> do hotel ou responsável pela conta.</li>
          <li><strong>Telefone</strong> de contato.</li>
          <li><strong>Email</strong> para comunicação e acesso à plataforma.</li>
          <li><strong>Endereço</strong> do hotel, para identificação e localização dos estabelecimentos que utilizam o Fives.</li>
        </ul>

        <h2>2. Finalidade do Tratamento dos Dados</h2>
        <p>Utilizamos os dados coletados para:</p>
        <ul>
          <li><strong>Comunicação</strong>: Facilitar a comunicação entre a Fives App e o hotel, incluindo notificações sobre atualizações, manutenção e suporte.</li>
          <li><strong>Gestão de Acesso</strong>: Verificar a identidade e conceder acesso seguro aos usuários autorizados.</li>
          <li><strong>Suporte Técnico</strong>: Fornecer atendimento e resolver problemas técnicos que possam surgir durante o uso da plataforma.</li>
          <li><strong>Marketing e Informações Promocionais</strong>: Enviar informações sobre atualizações, recursos e promoções da plataforma, caso o cliente autorize o recebimento desse tipo de comunicação.</li>
        </ul>

        <h2>3. Compartilhamento de Dados com Terceiros</h2>
        <p>A Fives App não compartilha, vende ou aluga dados pessoais dos seus clientes com terceiros, exceto nos seguintes casos:</p>
        <ul>
          <li><strong>Provedores de Serviços</strong>: Podemos compartilhar dados com parceiros que auxiliam na prestação do serviço, como provedores de hospedagem de dados e ferramentas de suporte. Esses provedores são contratualmente obrigados a proteger os dados e a utilizá-los apenas conforme instruções da Fives App.</li>
          <li><strong>Obrigação Legal</strong>: Em caso de exigência legal, judicial ou administrativa, a Fives App poderá compartilhar informações para cumprir com a legislação vigente.</li>
        </ul>

        <h2>4. Segurança dos Dados</h2>
        <p>Adotamos medidas de segurança técnicas e organizacionais para proteger os dados dos clientes contra acesso não autorizado, perda, alteração ou destruição. Entre as práticas de segurança estão o uso de criptografia, controle de acesso e auditorias periódicas de segurança.</p>

        <h2>5. Retenção dos Dados</h2>
        <p>Os dados coletados serão mantidos pela Fives App pelo período necessário para cumprir as finalidades descritas nesta Política de Privacidade, ou conforme exigido por lei. Caso o cliente solicite o encerramento de sua conta, os dados serão excluídos, salvo se houver obrigação legal para a sua retenção.</p>

        <h2>6. Direitos dos Clientes</h2>
        <p>Os clientes do Fives têm os seguintes direitos sobre seus dados pessoais:</p>
        <ul>
          <li><strong>Acesso e Correção</strong>: Solicitar o acesso aos dados e correções de informações incorretas ou desatualizadas.</li>
          <li><strong>Exclusão</strong>: Solicitar a exclusão de dados pessoais, sujeito às exigências legais.</li>
          <li><strong>Revogação de Consentimento</strong>: Revogar o consentimento para o uso de dados pessoais, sendo informado das possíveis limitações de acesso ao serviço.</li>
        </ul>
        <p>Para exercer seus direitos, o cliente deve entrar em contato com a Fives App através do email <a href="mailto:contato@fivesapp.com">contato@fivesapp.com</a>.</p>

        <h2>7. Alterações nesta Política de Privacidade</h2>
        <p>A Fives App poderá atualizar esta Política de Privacidade periodicamente para refletir alterações em nossas práticas de dados. As alterações serão comunicadas na plataforma e, em alguns casos, por email. Recomendamos que os clientes revisem esta Política de tempos em tempos para se manterem informados sobre como protegemos suas informações.</p>

        <h2>8. Contato</h2>
        <p>Para dúvidas, solicitações ou informações adicionais sobre esta Política de Privacidade, entre em contato conosco:</p>
        <ul>
          <li><strong>Email</strong>: <a href="mailto:contato@fivesapp.com">contato@fivesapp.com</a></li>
        </ul>

        <p>Ao continuar utilizando o Fives, você confirma que leu, entendeu e concorda com esta Política de Privacidade.</p>

      </Box>

      <Copyright />
    </Container>
  )
}