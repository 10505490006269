import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loading from "../../components/Loading";
import ScheduleIcon from "@mui/icons-material/Schedule";
import EditIcon from "@mui/icons-material/Edit";
import ButtonStyle from "../../styles/button.style";
import { Stack } from "@mui/material";

const columns = [
  /*{ id: "icon", label: "Imagem", minWidth: 20 },*/
  { id: "name", label: "Nome", minWidth: 100 },
  { id: "description", label: "Descrição", minWidth: 100 },
  { id: "duration", label: "Duração em horas", minWidth: 20 },
  { id: "start_time", label: "Hora início", minWidth: 50 },
  { id: "end_time", label: "Hora término", minWidth: 50 },
  { id: "action", label: "", minWidth: 100, align: "center" },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

const GridScheduler = (props) => {
  return (
    <Grid container spacing={3} style={{ marginTop: 20 }}>
      <Grid item xs={12}>
        {props.loading ? (
          <Loading color={"#4177F6"} text={"Carregando..."} />
        ) : (
          <>
            <Paper sx={{ width: "100%" }}>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: "#F5F6FA",
                            color: "#9FA2B4",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.rows.length > 0 &&
                      props.rows.map((row, index) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell style={styleRow}>{row.name}</TableCell>
                            <TableCell style={styleRow}>
                              {row.description}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {row.duration}
                            </TableCell>
                            <TableCell>{row.start_time}</TableCell>
                            <TableCell>{row.end_time}</TableCell>
                            <TableCell align="right" style={styleRow}>
                              <Stack
                                direction="row"
                                justifyContent="space-between"
                              >
                                <Button
                                  variant="contained"
                                  style={ButtonStyle.default}
                                  elevation={0}
                                  sx={{ marginRight: 2 }}
                                  onClick={() => {
                                    props.openScheduler(row);
                                  }}
                                >
                                  <ScheduleIcon title="Agendamentos" />
                                </Button>
                                <Button
                                  variant="contained"
                                  style={ButtonStyle.default}
                                  elevation={0}
                                  onClick={() => {
                                    props.openEdit(row);
                                  }}
                                >
                                  <EditIcon title="Alterar" />
                                </Button>
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default GridScheduler;
