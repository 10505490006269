import axios from "axios";
import { formatDateEn } from "../utils/dateUtils";

export function saveSchedulerAdmin(hotel_uuid, scheduler_uuid, scheduler) {
  if (scheduler_uuid) {
    return axios.put(
      `/api/v1/scheduler-admin/${hotel_uuid}/${scheduler_uuid}`,
      scheduler
    );
  } else {
    return axios.post(`/api/v1/scheduler-admin/${hotel_uuid}`, scheduler);
  }
}

export function deleteSchedulerAdmin(hotel_uuid, scheduler_uuid) {
  return axios.delete(
    `/api/v1/scheduler-admin/${hotel_uuid}/${scheduler_uuid}`
  );
}

export function getSchedulerById(scheduler_uuid) {
  return axios.get(`/api/v1/scheduler/${scheduler_uuid}`);
}

export function getAvailabilityScheduler(scheduler_uuid, date) {
  return axios.get(
    `/api/v1/scheduler/availability/${scheduler_uuid}/${formatDateEn(date)}`
  );
}

export function getAllSchedulerHotel(hotel_uuid) {
  return axios.get(`/api/v1/scheduler/hotel/${hotel_uuid}`);
}
