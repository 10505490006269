import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SubTitle from "../../components/SubTitle";
import SportsVolleyball from "@mui/icons-material/SportsVolleyball";

import DrawerActivities from "./DrawerActivities";
import Title from "../../components/Title";
import GridActivities from "./GridActivities";

const AddButton = styled(Button)({
  textTransform: "none",
});

function ActivitiesScreen({ loading, activities, reload }) {

  const [openDrawerActivity, setOpenDrawerActivity] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(null);

  useEffect(() => {
    reload();
  }, []);

  const close = (is_reload) => {
    setOpenDrawerActivity(false);
    setItemSelected(null)

    if (is_reload) {
      reload();
    }
  };

  const openEdit = (atividadeSelecioanda) => {
    console.log(atividadeSelecioanda)
    setItemSelected(atividadeSelecioanda);
    setOpenDrawerActivity(true);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Grid container sx={{ mt: 2, mb: 4 }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={10}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Title
              text="Atividades semanais"
              icon={<SportsVolleyball color="primary" />}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <AddButton
              variant="contained"
              color="primary"
              onClick={() => setOpenDrawerActivity(true)}
              startIcon={<AddIcon />}
            >
              Adicionar
            </AddButton>
          </Grid>
        </Grid>

        <SubTitle
          text={`Cadastre todas as atividades por dia da semana para que os hóspedes 
          consigam acomopanhar diariamente o que fazer em seu hotel.`}
        />
        <SubTitle
          text={`Ex.: Café da manhã, hidroginástica, teatro infantil...`}
        />

        <GridActivities
          rows={activities}
          loading={loading}
          openEdit={openEdit}
        />
      </Container>

      {openDrawerActivity ? (
        <DrawerActivities {...itemSelected} close={close} />
      ) : null}

    </Box>
  );
}

export default ActivitiesScreen;
