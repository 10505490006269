import React, { useState } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../components/SubTitle";
import IntlCurrencyInput from "react-intl-currency-input";
import DaysOfWeek from "../../components/DaysOfWeek";
import { HOTEL_UUID, currencyConfig } from "../../utils/constants";
import {
  saveSchedulerAdmin,
  deleteSchedulerAdmin,
} from "../../api/schedulerController";
import { toast } from "react-toastify";
import ButtonLoading from "../../components/layout/ButtonLoading";
import DialogConfirm from "../../components/DialogConfirm";

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#252733",
    letterSpacing: "0.4px",
    lineHeight: "24px",
  },
  local: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
  },
  date: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
    opacity: "0.54",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },

  data: {
    fontSize: 14,
    fontWeight: 700,
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px",
  },
};

const drawerWidth = 600;

function DrawerScheduler(props) {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState(props.name || "");
  const [duration, setDuration] = useState(props.duration || 1);
  const [value, setValue] = useState(props.value || "");
  const [description, setDescription] = useState(props.description || "");
  const [days_of_week, setDaysOfWeek] = useState(
    props.days_of_week || [0, 1, 2, 3, 4, 5, 6]
  );
  const [start_time, setStartTime] = useState(props.start_time || "08:00");
  const [end_time, setEndTime] = useState(props.end_time || "18:00");
  //const [icon, setIcon] = useState(props.icon || "");

  const [loadingButton, setLoadingButton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const addDaysOfWeek = (value) => {
    days_of_week.push(parseInt(value));
    setDaysOfWeek([...days_of_week]);
  };

  const delDaysOfWeek = (value) => {
    setDaysOfWeek([...days_of_week.filter((i) => i !== parseInt(value))]);
  };

  const save = () => {
    let payload = {
      name: name,
      description: description,
      value: value,
      duration: duration,
      days_of_week: days_of_week,
      start_time: start_time,
      end_time: end_time,
      //icon: icon,
    };

    setLoadingButton(true);
    saveSchedulerAdmin(localStorage.getItem(HOTEL_UUID), props.id, payload)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
      })
      .catch(() => {
        toast.error("Erro, tente novamente mais tarde!");
        setLoadingButton(false);
      });
  };

  const deleteItem = (responseOk) => {
    setOpenDelete(false);
    if (!responseOk) {
      return;
    }
    
    setLoadingButton(true);
    deleteSchedulerAdmin(localStorage.getItem(HOTEL_UUID), props.id)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
      })
      .catch(() => {
        toast.error("Erro, tente novamente mais tarde!");
        setLoadingButton(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
  };

  const valueChange = (event, value, maskedValue) => {
    event.preventDefault();
    setValue(maskedValue);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Novo tipo de serviço</span>
      </div>
      <Divider />

      <form onSubmit={handleSubmit}>
        <div style={styles.box}>
          <SubTitle text="Informações do serviço" variant="subtitle2" />
          <div style={styles.boxAddPhone}>
            <TextField
              id="outlined-basic"
              label="Nome"
              variant="outlined"
              placeholder="Nome do serviço"
              required
              style={styles.itemBox}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              placeholder="descrição"
              required
              multiline
              rows={4}
              style={styles.itemBox}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value.length <= 120 ? e.target.value : e.target.value.substring(0, 120))
              }}
              helperText={`${description.length}/120`}
            />

            {/*<div style={styles.dateContainer}>
              <span style={TextStyle.subTitle}>Imagem: </span>
              <ListIconScheduler setIcon={setIcon} />
    </div>*/}

            <div style={styles.dateContainer}>
              <IntlCurrencyInput
                component={TextField}
                currency="BRL"
                label="Valor"
                config={currencyConfig}
                value={value}
                onChange={valueChange}
                sx={{ width: "20ch" }}
              />
              <TextField
                id="outlined-basic"
                label="Duração em horas"
                variant="outlined"
                type="tel"
                required
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                sx={{ width: "20ch" }}
                focused
              />
            </div>

            <SubTitle text="Horário de funcionamento" variant="subtitle2" />
            <div style={styles.dateContainer}>
              <TextField
                id="outlined-basic"
                label="Hora de início"
                variant="outlined"
                type="time"
                required
                value={start_time}
                onChange={(e) => setStartTime(e.target.value)}
                sx={{ width: "20ch" }}
              />
              <TextField
                id="outlined-basic"
                label="Hora de término"
                variant="outlined"
                type="time"
                required
                value={end_time}
                onChange={(e) => setEndTime(e.target.value)}
                sx={{ width: "20ch" }}
              />
            </div>
            <div style={styles.dateContainer}>
              <DaysOfWeek
                days_of_week={days_of_week}
                addDaysOfWeek={addDaysOfWeek}
                delDaysOfWeek={delDaysOfWeek}
              />
            </div>

            <ButtonLoading label="Salvar" loading={loadingButton} />
            {props.id ? (
              <ButtonLoading
                label="Excluir"
                loading={loadingButton}
                onClick={() => setOpenDelete(true)}
                delete
              />
            ) : null}
          </div>
        </div>
      </form>
      {openDelete && (
        <DialogConfirm
          onResponse={deleteItem}
          entity="esse serviço"
        />
      )}
    </SwipeableDrawer>
  );
}

export default DrawerScheduler;
