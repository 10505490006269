import React, {useState, useEffect} from 'react';
import { SwipeableDrawer, Button } from '@mui/material';
import { enableWebPushNotification } from '../fives-web-push'

const styles = {
  title: {
    //fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '12px'
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
    marginTop: '10px',
    width: '200px'
  }
}

const drawerWidth = '100%';

function WebPush (props) {
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    if (Notification.permission === "denied") {
      console.log("Usuário não permitiu o push notification ;(");
      return;
    }
    if (Notification.permission === "default") {
      setOpen(true)
    }
  },[])

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }  
    setOpen(open);
  };

  const enableWebPush = () => {
    enableWebPushNotification()
    setOpen(false)
  }

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex:'1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      >
        <div style={styles.center}>
          <div>
            <span style={styles.title}>
              Deseja receber as notificações do fives?
            </span>
          </div>
          <div style={styles.rowBetween}>
            <Button
              variant='outlined' 
              elevation={0}
              type="button"
              onClick={toggleDrawer(false)}
            >
              Não
            </Button>
            <Button
              variant='contained' 
              elevation={0}
              type="button"
              onClick={enableWebPush}              
            >
              Sim
            </Button>           
          </div>
        </div>     
    </SwipeableDrawer>
  )
}

export default WebPush;