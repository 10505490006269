import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loading from "../../components/Loading";
import { Chip } from "@mui/material";

const columns = [
  { id: "start_time", label: "Hora início", minWidth: 50 },
  { id: "end_time", label: "Hora término", minWidth: 50 },
  { id: "room", label: "Quarto", minWidth: 50 },
  { id: "status", label: "Situação", minWidth: 50 },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

const GridSchedulerAvailable = (props) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {props.loading ? (
          <Loading color={"#4177F6"} text={"Carregando..."} />
        ) : (
          <>
            <Paper sx={{ width: "100%" }}>
              <TableContainer>
                <Table aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            top: 57,
                            minWidth: column.minWidth,
                            backgroundColor: "#F5F6FA",
                            color: "#9FA2B4",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {props.rows.length > 0 &&
                      props.rows.map((row, index) => {
                        return (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell style={styleRow}>{row.start}</TableCell>
                            <TableCell style={styleRow}>{row.end}</TableCell>
                            <TableCell style={styleRow}>{row.room}</TableCell>
                            <TableCell style={styleRow}>
                              {row.room ? (
                                <Chip
                                  label="Reservado"
                                  color="error"
                                  variant="outlined"
                                />
                              ) : (
                                <Chip
                                  label="Disponível"
                                  color="success"
                                  variant="outlined"
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default GridSchedulerAvailable;
