import React, {useState} from 'react';
import HotelinfoScreen from './Hotelinfo.screen';
import { useHotel } from '../../contexts/HotelProvider';

export default function HotelinfoContainer() {
  const [loading, setLoading] = useState(false);
  const { hotel, getHotelData } = useHotel();

  const reload = async () => {
    setLoading(true)
    await getHotelData()
    setLoading(false)
  }

  if(loading) {
    return
  }

  return (
    <HotelinfoScreen
      loading={loading}
      hotel={hotel}
      reload={reload}
    />
  )    
}