import React, { useState, useEffect } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TextStyle from "../styles/text.style";
import SubTitle from "./SubTitle";
import { PatternFormat } from "react-number-format";
import {
  saveListPrecheckin,
  getAllPrecheckin,
  deletePrecheckin,
} from "../api/precheckinController";
import { HOTEL_UUID, HOTEL_DATA } from "../utils/constants";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TableStyle from "../styles/table.style";
import ButtonStyle from "../styles/button.style";
import ButtonLoading from "./layout/ButtonLoading";
import {
  formatDatePtBr,
  convertStringToDatePTBR,
  convertDate,
  toDay,
} from "../utils/dateUtils";
import { truncate } from "../utils/str";
const xls = require("../assets/xls.png");
const styles = {
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    paddingLeft: "31px",
    paddingRight: "31px",
    paddingTop: "20px",
    paddingBottom: "20px",
  },

  collum: {
    display: "flex",
    flexDirection: "collum",
    padding: "12px",
  },

  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px",
  },
  box: {
    paddingLeft: "31px",
    paddingRight: "31px",
    paddingTop: "20px",
    paddingBottom: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    // paddingTop: "20px",
    paddingBottom: "20px",
  },
  boxAddPhone: {
    padding: "20px",
    width: "100%",
    background: "#E8EAEE",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
  },
  boxUploadCSV: {
    padding: "30px",
    width: "100%",
    display: "flex",
    background: "#FFFFFF",
    border: "1px solid #C4C4C4",
    borderRadius: "4px",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
  },
};

const drawerWidth = 600;

function DrawerPrecheckin(props) {
  const [open, setOpen] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [checkin, setCheckin] = useState("");
  const [checkout, setCheckout] = useState("");
  const [room, setRoom] = useState("");
  const [loading, setLoading] = useState(false);
  const [guests, setGuests] = useState([]);
  const [hotel, setHotel] = useState();
  const [inGroup, setInGroup] = useState(props.inGroup || false);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];

    var reader = new FileReader();
    reader.onload = (e) => {
      let lines = e.target.result.split("\r\n");
      let header = lines[0];
      if (header !== "name;email;phone;room;checkin;checkout") {
        alert(
          "Arquivo não está no padrão, baixe o template preencha as informações e tente novamente"
        );
        return;
      }

      for (let i = 1; i < lines.length; i++) {
        let columns = lines[i].split(";");
        const guest = {
          name: columns[0],
          email: columns[1],
          phone: columns[2],
          room: columns[3],
          checkin: convertDate(columns[4] + ""),
          checkout: convertDate(columns[5] + ""),
          hotel_uuid: localStorage.getItem(HOTEL_UUID),
          hotel_name: hotel.name,
        };
        guests.push(guest);
      }
      setGuests([...guests]);
    };
    reader.readAsText(fileUploaded);
  };

  useEffect(() => {
    setHotel(JSON.parse(localStorage.getItem(HOTEL_DATA)));
  }, []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const addGuest = (e) => {
    e.preventDefault();

    if (!isValidDate(checkin, checkout)) {
      return;
    }

    const guest = {
      name: name,
      email: email,
      phone: phone,
      room: room,
      checkin: checkin,
      checkout: checkout,
    };

    guests.push(guest);
    setGuests([...guests]);
  };

  const isValidDate = (checkin, checkout) => {
    let checkinDate = convertDate(checkin);
    let checkoutDate = convertDate(checkout);
    let today = toDay();

    if (checkinDate.getTime() < today.getTime()) {
      alert(
        `A data de entrada(${formatDatePtBr(
          checkinDate
        )}) não pode ser menor que a data atual!`
      );
      return false;
    }
    if (checkinDate.getTime() > checkoutDate.getTime()) {
      alert(
        `A data de entrada(${formatDatePtBr(
          checkinDate
        )}) não pode ser maior que a data de saída!`
      );
      return false;
    }

    if (checkinDate.getTime() === checkoutDate.getTime()) {
      alert(
        `A data de entrada(${formatDatePtBr(
          checkinDate
        )}) não pode ser igual que a data de saída!`
      );
      return false;
    }
    return true;
  };

  const delGuest = (index) => {
    guests.splice(index, 1);
    setGuests([...guests]);
  };

  const cleanForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRoom("");
    setCheckin("");
    setCheckout("");
  };

  const confirmSave = () => {
    setLoading(true);
    saveListPrecheckin({
      hotel_uuid: localStorage.getItem(HOTEL_UUID),
      hotel_name: hotel.name,
      guests: guests,
    })
      .then((response) => {
        cleanForm();
        toast.success("Pré checkin realizado com sucesso!");
        props.close(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Ocorreu um problema, tente mais tarde!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderSingle = () => {
    return (
      <>
        <div style={styles.row}>
          <div style={styles.buttonClose}>
            <IconButton component="span" onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <span style={TextStyle.title}>Entrada unitária</span>
        </div>
        <Divider />

        <form onSubmit={addGuest} name="form-add">
          <div style={styles.box}>
            <SubTitle text="Dados de entrada" variant="subtitle2" />
            <div style={styles.boxAddPhone}>
              <TextField
                label="Nome"
                variant="standard"
                placeholder="Nome do hóspede"
                required
                style={styles.itemBox}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <TextField
                label="Email"
                variant="standard"
                placeholder="Email"
                required
                style={styles.itemBox}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <div style={styles.dateContainer}>

                <PatternFormat
                  customInput={TextField}
                  label="Telefone"
                  variant="standard"
                  placeholder="(xx) xxxxx-xxxx"
                  format="(##) #####-####"
                  value={phone}
                  style={{ ...styles.itemBox, width: 220, marginTop: 0 }}
                  onChange={(e) => setPhone(e.target.value)}
                />

                <TextField
                  label="Quarto"
                  variant="standard"
                  placeholder="Quarto"
                  required
                  style={{ ...styles.itemBox, width: 220, marginTop: 0 }}
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                />
              </div>

              <div style={styles.dateContainer}>
                <TextField
                  label="Entrada"
                  variant="standard"
                  type="date"
                  value={checkin}
                  onChange={(e) => setCheckin(e.target.value)}
                  focused
                  style={{ width: 220 }}
                />
                <TextField
                  label="Saída"
                  variant="standard"
                  type="date"
                  value={checkout}
                  onChange={(e) => setCheckout(e.target.value)}
                  focused
                  style={{ width: 220 }}
                />
              </div>

              <Button
                style={ButtonStyle.primary}
                type="submit"
                variant="contained"
                elevation={0}
                fullWidth
              >
                Adicionar
              </Button>
            </div>
          </div>
        </form>
      </>
    );
  };

  const renderInGroup = () => {
    return (
      <>
        <div style={styles.row}>
          <div style={styles.buttonClose}>
            <IconButton component="span" onClick={toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </div>
          <span style={TextStyle.title}>Entrada de grupo</span>
        </div>
        <Divider />

        <div style={styles.box}>
          <Stack direction="row" justifyContent="space-between" mb="16px">
            <Typography variant="h5" style={TextStyle.subTitle}>
              Upload CSV
            </Typography>
            <Stack direction="row" alignContent="center">
              <a
                href="https://s3.amazonaws.com/br.com.fivesapp/templates/fivesCSV.csv"
                target="_blank"
                rel="noreferrer"
                style={{
                  color: "#21A265",
                  fontWeight: "500",
                  fontSize: "14px",
                  lineHeight: "24px",
                  align: "right",
                }}
              >
                Downdload template
              </a>
              <img src={xls} height={24} width={40} alt="CSV" />
            </Stack>
          </Stack>
          <div style={styles.boxAddPhone}>
            <div style={styles.boxUploadCSV}>
              <Typography sx={TextStyle.subTitle}>
                Selecione o documento
              </Typography>
              <Typography
                sx={[TextStyle.textDescription, { textAlign: "center" }]}
              >
                Você precisa selecionar o documento CSV para
                <br />
                efetuarmos o upload dos dados.
              </Typography>
              <Button
                sx={[ButtonStyle.primary, { marginTop: "24px" }]}
                type="button"
                variant="contained"
                elevation={0}
                fullWidth
                onClick={handleClick}
              >
                Selecionar documento
              </Button>

              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      {inGroup ? renderInGroup() : renderSingle()}

      <div style={styles.box}>
        <SubTitle text="Confirmação" variant="subtitle2" />
        <TableContainer sx={{ maxHeight: 900, marginTop: "10px" }}>
          {loading && <LinearProgress />}
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left" style={TableStyle.head}>
                  Nome
                </TableCell>
                <TableCell align="left" style={TableStyle.head}>
                  Email
                </TableCell>
                <TableCell align="left" style={TableStyle.head}>
                  Entrada
                </TableCell>
                <TableCell align="left" style={TableStyle.head}>
                  Saída
                </TableCell>
                <TableCell align="left" style={TableStyle.head}>
                  Edição
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {guests.map((row, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell style={TableStyle.textRow} title={row.name}>
                      {truncate(row.name)}
                    </TableCell>
                    <TableCell style={TableStyle.textRow} title={row.email}>
                      {truncate(row.email)}
                    </TableCell>
                    <TableCell style={TableStyle.textRow} align="center">
                      {formatDatePtBr(new Date(row.checkin))}
                    </TableCell>
                    <TableCell style={TableStyle.textRow} align="center">
                      {formatDatePtBr(new Date(row.checkout))}
                    </TableCell>
                    <TableCell style={TableStyle.textRow} align="center">
                      <Button
                        variant="contained"
                        style={ButtonStyle.default}
                        elevation={0}
                        onClick={() => delGuest(index)}
                      >
                        <CloseIcon
                          color="primary"
                          sx={{
                            borderRadius: "2px",
                          }}
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {guests.length > 0 && (
          <ButtonLoading
            type="button"
            label="Confirmar"
            loading={loading}
            onClick={confirmSave}
          />
        )}
      </div>
    </SwipeableDrawer>
  );
}

export default DrawerPrecheckin;
