const TextStyle = {
  title: {
    fontFamily: 'Poppins',
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  subTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
  },
  textSuggestion: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    opacity: '0.5'
  },
  label: {
    fontFamily: 'Mulish',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '10px',
    lineHeight: '20px',
    textAlign: 'right',
    letterSpacing: '0.2px',
    color: '#252733'
  },
  titleHotelinfo: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '40px',
    lineHeight: '60px',
    letterSpacing: '1px',
    color: '#4177F6'
  },
  buttonDefault: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontHeight: '700',
    fontSize: '14px',
    lineHeight: '16px',
    textAlign: 'center'
  },
  textDescription: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '19px',
    color: '#252733'
  },
}

export default TextStyle;