import React, { useEffect, useState } from 'react';
import StackBarChart from './StackedBarChart.screen';
import { Skeleton } from "@mui/material";
import { getAllRatingCurrentYear } from '../../api/rateController';
import { useHotel } from '../../contexts/HotelProvider';

export default function StackBarCharContainer() {
  const [loading, setLoading] = useState(true);
  const [ratings, setRatings] = useState([]);
  const { hotelUID } = useHotel();

  useEffect(() => {
    loadingRatings();
  }, []);

  const loadingRatings = async () => {
    setLoading(true);
    
    getAllRatingCurrentYear(hotelUID)
      .then(response => {
        setRatings(response.data)
      }).finally(() => {
        setLoading(false);
      })

  }

  return (
    loading
      ? <Skeleton variant="rect" width={"100%"} height={134} sx={{ mt: 2, borderRadius: '4px', backgroundColor: '#E8EAEE' }} />
      : <StackBarChart data={ratings} />
  );
}
