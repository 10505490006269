import axios from 'axios';

export function saveListPrecheckin(payload) {
  return axios.post('/api/v1/precheckin-list', payload)
}

export function getAllPrecheckin(hotel_uuid) {
  return axios.get(`/api/v1/precheckin/hotel/${hotel_uuid}`)
}

export function deletePrecheckin(id) {
  return axios.delete(`/api/v1/precheckin/${id}`)
}

export function printPDF(hotel_uuid, token_id) {
  return axios.get(
    `https://report.fivesapp.com.br/api/v1/precheckin/${hotel_uuid}/${token_id}`,
    {
      responseType: "blob",
    }
  )
}

export async function editPrecheckin(precheckin) {
  return axios.post(`/api/v1/precheckin/fill`, precheckin)
}