import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  title,
  title2,
  subtitle1,
} from "./Signup.style";
import { PatternFormat } from "react-number-format";
import ButtonLoading from "../../components/layout/ButtonLoading";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { strengthColor, strengthIndicator } from "../../utils/password-strength";
import { isEmail } from "../../utils/str";
import Copyright from "../../components/Copyright";
import { saveHotel } from "../../api/hotelController";
import { toast } from "react-toastify";


const theme = createTheme();

function SignupScreen() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [level, setLevel] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [agreePolicy, setAgreePolicy] = useState(false);

  const [error, setError] = useState(false);

  const [finish, setFinish] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseUpPassword = (event) => {
    event.preventDefault();
  };

  const changePassword = (value) => {
    const temp = strengthIndicator(value);
    setLevel(strengthColor(temp));
    setPassword(value);
    setDisabled(temp < 4);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    saveHotel(null, {
      name: name,
      contactName: contactName,
      contactPhone: contactPhone,
      email: email,
      password: password,
      owner: email
    }).then((response) => {
      setLoading(false);
      if(response.data){
        toast.success("Cadastro realizado com sucesso!");
        setFinish(true);
      } else {
        toast.error("Ocorreu um problema, tente mais tarde!");
      }
    }).catch((e) => {
      setLoading(false);
      toast.error(e.response.data.message);
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + "/background-fives-login.png"
              })`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item sm={12} md={5} component={Paper} elevation={0}>
          {finish ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                padding: "5%",
              }}
            >
              <img
                src={process.env.PUBLIC_URL + "/fives.png"}
                height={56}
                width={149}
                alt="Fives"
                style={{ marginBottom: 30 }}
              />

              <span style={title2}>Cadastro realizado com sucesso!</span>
              <span style={title2}>Todas as informções foram enviadas para o seu email.</span>
            </Box>
          ) :
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              padding: "5%",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/fives.png"}
              height={56}
              width={149}
              alt="Fives"
              style={{ marginTop: 100 }}
            />

            <span style={title}>Criar conta</span>

            <Box
              component="form"
              onSubmit={handleSubmit}
              style={{ fontFamily: "Poppins"}}
            >
              <span style={subtitle1}>Preencha os campos para criar uma conta para o seu hotel.</span>
              
              <TextField
                margin="normal"
                required
                fullWidth
                id="name"
                label="Nome do Hotel"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="contactName"
                label="Nome do Responsável"
                name="contactName"
                value={contactName}
                onChange={(e) => setContactName(e.target.value)}
                autoFocus
              />

              <PatternFormat
                margin="normal"
                id="outlined-basic"
                sx={{ width: "100%" }}
                customInput={TextField}
                label="Telefone do Responsável"
                variant="outlined"
                placeholder="(xx) xxxxx-xxxx"
                format="(##) #####-####"
                value={contactPhone}
                onChange={(e) => setContactPhone(e.target.value)}
              />

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="E-mail"
                name="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                  isEmail(e.target.value) ? setError(false) : setError(true)
                }}
                autoFocus
                error={error}
                helperText={error ? "Endereço de e-mail inválido" : ""}
              />

              <FormControl sx={{ mt: 2, width: "100%" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">Senha</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPassword ? 'hide the password' : 'display the password'
                        }
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseUpPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Senha"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => changePassword(e.target.value)}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mt: 2 }}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Box
                      sx={{
                        bgcolor: level?.color,
                        width: 85,
                        height: 8,
                        borderRadius: '7px'
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle1" fontSize="0.75rem">
                      {level?.label}
                    </Typography>
                  </Grid>
                </Grid>
              </FormControl>

              <Stack direction="row" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      id="receiveNotifications-signup"
                      checked={agreePolicy}
                      onChange={(e) => setAgreePolicy(e.target.checked)}
                      name="agreePolicy"
                    />
                  }
                />
                <Typography variant="body2">
                  Li e concordo com os &nbsp;
                  <Link variant="subtitle2" href="/terms-of-use" target="_blank">
                    Termos de uso&nbsp;
                  </Link>
                  e&nbsp;
                  <Link variant="subtitle2" href="/privacy-policy" target="_blank">
                    Política de Privacidade
                  </Link>
                </Typography>
              </Stack>


              <ButtonLoading label="Criar Conta" loading={loading} disabled={disabled || !agreePolicy || error} />

            </Box>
          </Box>
          }
          <Copyright />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default SignupScreen;
