import React from 'react';
import { Paper, Grid, Box, styled } from '@mui/material';
import Guests from './Guests';
import BoxNotificationsHelper from './notifications/BoxNotificationsHelper';
import StackBarCharContainer from '../screens/chart/StackedBarChart.container';

const styles = {
  title: {
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  subTitle: {
    fontStyle: 'normal',
    fontHeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.1px',
    color: '#9FA2B4'
  },
  rateNote: {
    color: '#000',
    fontSize: 32,
    fontWeight: '800',
    letterSpacing: '0.3px',
  },
  rateDescription: {
    color: '#9FA2B4',
    fontSize: 16,
    letterSpacing: '0.3px',
    lineHeight: '22px'
  }
}

const BoxRating = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,
  margin: 0,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #DFE0EB',
}));

function TabGeral(props) {
  const currentDate = new Date();

  return (
    <Grid container spacing={3} >
      <Grid item xs={12} pb={4} >
        <Grid container>
          <Grid item xs={12} md={9}
            sx={{
              p: 2,
              border: '1px solid #DFE0EB',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                height: 490,
              }}
            >
              <span style={styles.title}>Média do NPS</span><br />
              <span style={styles.subTitle}>Atualizado às {currentDate.toLocaleString('pt-BR')}</span><br />
              {<StackBarCharContainer />}
            </Paper>
          </Grid>
          <Grid item xs={12} md={3}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <BoxRating>
              <span style={styles.rateNote}>{props.hotel ? props.hotel.avgParkingRating : ''}</span>
              <span style={styles.rateDescription}>Estacionamento</span>
            </BoxRating>
            <BoxRating>
              <span style={styles.rateNote}>{props.hotel ? props.hotel.avgCleaningRating : ''}</span>
              <span style={styles.rateDescription}>Limpeza</span>
            </BoxRating>
            <BoxRating>
              <span style={styles.rateNote}>{props.hotel ? props.hotel.avgTreatmentRating : ''}</span>
              <span style={styles.rateDescription}>Atendimento</span>
            </BoxRating>
            <BoxRating>
              <span style={styles.rateNote}>{props.hotel ? props.hotel.avgRecreationRating : ''}</span>
              <span style={styles.rateDescription}>Recreação</span>
            </BoxRating>
            <BoxRating>
              <span style={styles.rateNote}>{props.hotel ? props.hotel.avgInfrastructureRating : ''}</span>
              <span style={styles.rateDescription}>Infraestrutura</span>
            </BoxRating>
          </Grid>
        </Grid>
      </Grid>
      {/* Hospedes */}
      <Grid item xs={12} md={9}>
        <Guests />
      </Grid>
      <Grid item xs={12} md={3}>
        <BoxNotificationsHelper />
      </Grid>
    </Grid>
  )
}

export default TabGeral;