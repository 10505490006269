import React, { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { title, subtitle2 } from "./Login.style"
import * as userController from "../api/userController";
import Copyright from "../components/Copyright";

const theme = createTheme();

export default function ForgotPassword() {

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const forgotPassword = () => {
    setLoading(true)
    userController.sendForgotPassword(email)
      .then(() => {
        //navigate('/login');
      }).finally(() => {
        setLoading(false)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    forgotPassword();
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={false}
          md={7}
          sx={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/background-fives-login.png'})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={12} md={5} component={Paper} elevation={0}>
          <Box
            sx={{
              display: "flex",
              height: "100vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              padding: "5%",
            }}
          >
            <img src={process.env.PUBLIC_URL + '/fives.png'} height={56} width={149} alt="Fives" />

            <span style={title}>
              Controller
            </span>
            <br />
            <span style={subtitle2}>
              Digite seu e-mail de recuperação
            </span>
            <Box component="form"
              onSubmit={handleSubmit}
              style={{ fontFamily: "Poppins", width: "100%" }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                type="submit"
                disabled={loading}
              >
                Enviar
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    Voltar para o login
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}