var currency = require("currency.js");

export function numberToRealBR(amount) {
  return "R" + currency(amount).format();
}

export function formatFloat(value) {
  const formatCurrency = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  return formatCurrency.format(value);
}

/**
 * Método reponsável por formatar e converter um valor para Float
 * @param value         Valor a ser convertido
 * @returns {number}
 */
export const convertStringToNumber = (value) => {
  if (typeof value === "string") {
    if (value.indexOf("R$") !== -1) {
      value = value.substring("R$ ".length).replace(".", "");
    }
    value = value.replace(",", ".");
  }

  return Number(parseFloat(value).toFixed(2));
};
