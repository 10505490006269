import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Copyright from '../components/Copyright';

export default function TermsOfUse() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/fives.png"}
          height={56}
          width={149}
          alt="Fives"
          style={{ marginTop: 100 }}
        />

        <h1>Termos de Uso - Fives App</h1>

        <p>Estes Termos de Uso regem o acesso e o uso do Fives, uma plataforma de Software as a Service (SaaS) desenvolvida pela Empresa Fives App para facilitar o gerenciamento de relacionamento entre hotéis e seus hóspedes. Ao utilizar o Fives, você concorda com estes Termos de Uso. Caso não concorde, recomendamos não utilizar o serviço.</p>

        <h2>1. Definições</h2>
        <p><strong>Fives App</strong> ou <strong>Fives</strong>: Plataforma SaaS fornecida pela Empresa para gerenciar a comunicação e o relacionamento de hotéis com seus hóspedes.</p>
        <p><strong>Cliente</strong>: Pessoa física ou jurídica (hotel) que adquire e utiliza o Fives para gerenciar o relacionamento com seus hóspedes.</p>
        <p><strong>Hóspede</strong>: Pessoa que se hospeda no hotel e que pode interagir com a plataforma através de comunicações gerenciadas pelo hotel.</p>
        <p><strong>Conteúdo</strong>: Qualquer material compartilhado por meio da plataforma, incluindo mensagens, reservas, avaliações, feedback, e dados relacionados ao atendimento dos hóspedes.</p>

        <h2>2. Aceitação dos Termos</h2>
        <p>Ao se cadastrar, acessar ou usar o Fives, o Cliente declara que leu, compreendeu e concorda em cumprir estes Termos de Uso. Caso o Cliente não concorde com qualquer parte destes Termos, deve cessar o uso do serviço.</p>

        <h2>3. Cadastro e Segurança da Conta</h2>
        <p>O Cliente deve fornecer informações precisas, atuais e completas para criar sua conta no Fives e é responsável por manter a segurança de suas credenciais de acesso. Todas as atividades realizadas através da conta do Cliente são de sua responsabilidade, incluindo o gerenciamento de permissões para membros de sua equipe.</p>

        <h2>4. Uso do Serviço</h2>
        <p>O Fives é destinado exclusivamente a hotéis e outros estabelecimentos de hospedagem para uso profissional. O Cliente compromete-se a:</p>
        <ul>
          <li><strong>Utilização Legal e Ética</strong>: Utilizar o Fives em conformidade com as leis aplicáveis, respeitando a privacidade dos hóspedes e o direito de cada um ao sigilo de suas informações pessoais.</li>
          <li><strong>Dados dos Hóspedes</strong>: Tratar as informações dos hóspedes de forma confidencial, usando os dados exclusivamente para finalidades relacionadas ao relacionamento com os mesmos.</li>
          <li><strong>Propriedade Intelectual</strong>: Reconhecer que todo o conteúdo e funcionalidades da plataforma (exceto os dados dos hóspedes fornecidos pelo Cliente) são propriedade da Fives App.</li>
        </ul>

        <h2>5. Responsabilidade sobre o Conteúdo</h2>
        <p>A responsabilidade pelo conteúdo gerado e compartilhado por meio do Fives é exclusiva do Cliente. A Fives App não assume responsabilidade por comunicações indevidas, informações incorretas ou uso indevido de dados por parte do Cliente ou de terceiros.</p>

        <h2>6. Propriedade Intelectual</h2>
        <p>Todos os direitos relativos ao software, design, funcionalidades e conteúdo do Fives são de propriedade exclusiva da Fives App. O Cliente não adquire qualquer direito sobre a propriedade intelectual do serviço ao usá-lo, exceto o direito de uso conforme especificado nestes Termos.</p>

        <h2>7. Privacidade e Dados Pessoais</h2>
        <p>A Fives App coleta, armazena e processa dados pessoais conforme sua <strong><a href="/privacy-policy" target="_blank" rel="noreferrer">Política de Privacidade</a></strong>. O Cliente concorda em adotar as medidas necessárias para proteger a privacidade dos dados dos hóspedes e para cumprir as leis de proteção de dados aplicáveis.</p>

        <h2>8. Suporte Técnico e Atualizações</h2>
        <p>A Fives App oferece suporte técnico conforme especificado no contrato de assinatura e se reserva o direito de realizar atualizações, modificações ou melhorias na plataforma sem aviso prévio. Interrupções temporárias de serviço podem ocorrer durante a implementação dessas atualizações.</p>

        <h2>9. Cancelamento e Encerramento</h2>
        <p>O Cliente pode encerrar o uso do Fives a qualquer momento, mediante aviso prévio, conforme acordado no contrato de assinatura. A Fives App também reserva o direito de suspender ou encerrar o acesso do Cliente ao serviço caso sejam violados estes Termos de Uso ou a legislação aplicável.</p>

        <h2>10. Limitação de Responsabilidade</h2>
        <p>A Fives App não se responsabiliza por quaisquer danos diretos, indiretos, incidentais, especiais ou consequenciais resultantes do uso ou da incapacidade de uso do Fives. O uso do Fives é oferecido "como está" e "conforme disponível", sem garantias de adequação a um fim específico.</p>

        <h2>11. Alterações nos Termos de Uso</h2>
        <p>A Fives App pode atualizar estes Termos de Uso periodicamente. Qualquer mudança será comunicada através da plataforma, e o uso contínuo do serviço após a publicação das alterações implica aceitação dos novos Termos.</p>

        <h2>12. Disposições Gerais</h2>
        <p>Se qualquer cláusula destes Termos for considerada inválida ou inexequível, as demais disposições permanecerão válidas e em vigor.</p>

        <h2>13. Lei Aplicável e Foro</h2>
        <p>Estes Termos de Uso são regidos pelas leis do Brasil. Quaisquer disputas relacionadas ao Fives serão resolvidas no foro da comarca de Palhoça/SC.</p>

        <p>Ao continuar utilizando o Fives, o Cliente confirma que leu, entendeu e concorda com estes Termos de Uso.</p>
      </Box>

      <Copyright />
    </Container>
  )
}