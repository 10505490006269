const TableStyle = {
  head: {
    minWidth: 30,
    backgroundColor: '#F5F6FA',
    color: '#9FA2B4',
    padding: '10px'
  },
  row: {
    fontSize: '14px',
    lineHeight: '20px',
    color: '#252733',
    fontWeight: '600',
  },
  textRow: {
    fontSize: '12px',
    lineHeight: '20px',
    color: '#252733',
    fontWeight: '500',
  }
}

export default TableStyle;