import axios from 'axios';

export function getPartners(hotel_uuid) {
  return axios.get(`/api/v1/partners/${hotel_uuid}`)
}

export function getCountPrincipalPartners(hotel_uuid) {
  return axios.get(`/api/v1/partners/${hotel_uuid}/principal/count`)
}

export function createUpdatePartners(hotel_uuid, payload, partner_uuid) {
  if (partner_uuid) {
    return axios.put(`/api/v1/partners/${hotel_uuid}/${partner_uuid}`,
      payload
    )
  }
  return axios.post(`/api/v1/partners/${hotel_uuid}`,
    payload
  )
}

export function setActivePartners(hotel_uuid, partner_uuid, status) {
  return axios.put(`/api/v1/partners/active/${hotel_uuid}/${partner_uuid}/${status}`)
}

export function deletePartners(hotel_uuid, partner_uuid) {
  return axios.delete(`/api/v1/partners/${hotel_uuid}/${partner_uuid}`)
}