// Cardápio do hotel
import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import ToggleButton from "@mui/material/ToggleButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";

import { styled } from "@mui/material/styles";
import ButtonStyle from "../../styles/button.style";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import Title from "../../components/Title";
import SubTitle from "../../components/SubTitle";
import Loading from '../../components/Loading';
import DrawerMenu from "./DrawerMenu";
import { TODOS } from "../../utils/constants";
import { DebounceInput } from "react-debounce-input";

import { HOTEL_UUID } from "./../../utils/constants";
import { changeStatusProduct } from "../../api/menuController";
import { formatFloat } from "./../../utils/stringUtils";

const AddButton = styled(Button)({
  textTransform: "none",
});

const columns = [
  { id: "icon", label: "Imagem", minWidth: 30 },
  { id: "title", label: "Título", minWidth: 100 },
  { id: "description", label: "Descritivo", minWidth: 200 },
  { id: "value", label: "Valor", minWidth: 50 },
  { id: "category_id", label: "Categoria", minWidth: 100 },
  { id: "menu_place_id", label: "Local", minWidth: 100 },
  { id: "Status", label: "Status", minWidth: 50 },
  { id: "Editar", label: "", minWidth: 50 },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

const listStatus = [
  { value: "ATIVO", label: "Ativo" },
  { value: "INATIVO", label: "Inativo" },
  { value: TODOS, label: "Todos" },
];

function MenuScreen(props) {
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState(TODOS);
  const [categoryId, setCategoryId] = React.useState(TODOS);
  const [menuPlaceId, setMenuPlaceId] = React.useState(TODOS);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [itemSelected, setItemSelected] = React.useState(null);

  const categoriesIndexed = [];
  props.categories.forEach((element) => {
    categoriesIndexed[element.value] = element.label;
  });

  const menuPlacesIndexed = [];
  props.menuPlaces.forEach((element) => {
    menuPlacesIndexed[element.value] = element.label;
  });

  const closeMenu = (reload) => {
    setOpenMenu(false);
    setItemSelected(null);
    if (reload) {
      props.reload();
    }
  };

  useEffect(() => {
    setItems(props.rows);
  }, [props.rows]);

  useEffect(() => {
    let rows = props.rows;
    if (categoryId !== TODOS) {
      rows = rows.filter((i) => i.category_id === categoryId);
    }

    if (menuPlaceId !== TODOS) {
      rows = rows.filter((i) => i.menu_place_id === menuPlaceId);
    }

    if (status !== TODOS) {
      if (status === "ATIVO") {
        rows = rows.filter((i) => i["active"]);
      } else {
        rows = rows.filter((i) => !i["active"]);
      }
    }

    if (!!search) {
      rows = rows.filter(
        (i) =>
          i.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
          i.description.toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    }

    setItems(rows);
  }, [search, status, categoryId, menuPlaceId]);

  const setActiveItem = (index) => {
    let newValue = !items[index]["active"];
    items[index]["active"] = newValue;
    changeStatusProduct(
      items[index].id,
      localStorage.getItem(HOTEL_UUID),
      newValue
    );
    setItems([...items]);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        height: "100vh",
      }}
    >
      <Container maxWidth="xl" sx={{ mb: 4 }}>
        <Grid container sx={{ mt: 2, mb: 4 }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            justifyContent="flex-start"
            alignItems="center"
          >
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-search" size="small">
                Busca
              </InputLabel>

              <DebounceInput
                id="outlined-search"
                value={search}
                minLength={1}
                label="Busca"
                debounceTimeout={300}
                onChange={(e) => setSearch(e.target.value)}
                element={OutlinedInput}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField
              fullWidth
              select
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              size="small"
            >
              {listStatus.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField
              fullWidth
              select
              label="Categoria"
              value={categoryId}
              onChange={(e) => setCategoryId(e.target.value)}
              size="small"
            >
              {props.categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField
              fullWidth
              select
              label="Local"
              value={menuPlaceId}
              onChange={(e) => setMenuPlaceId(e.target.value)}
              size="small"
            >
              {props.menuPlaces.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <AddButton
              variant="contained"
              color="primary"
              onClick={() => setOpenMenu(true)}
              startIcon={<AddIcon />}
            >
              Adicionar
            </AddButton>
          </Grid>
        </Grid>

        <SubTitle text={`Itens do cardápio (${items.length})`} />

        {props.loading ? (
          <Loading color={'#4177F6'} text={'Carregando...'} />
        ) : (
          <Paper sx={{ width: "100%" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          backgroundColor: "#F5F6FA",
                          color: "#9FA2B4",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        <TableCell style={styleRow}>
                          {row.icon ? (
                            <Avatar
                              alt="Comida"
                              variant="square"
                              src={row.icon}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                backgroundColor: "#E8EAEE",
                                height: "48px",
                                width: "48px",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 4,
                              }}
                            >
                              <CameraAltRoundedIcon color="primary" />
                            </div>
                          )}
                        </TableCell>
                        <TableCell style={styleRow}>{row.title}</TableCell>
                        <TableCell style={styleRow}>
                          {row.description}
                        </TableCell>
                        <TableCell align="right" style={styleRow}>
                          {formatFloat(row.value)}
                        </TableCell>
                        <TableCell style={styleRow}>
                          {categoriesIndexed[row.category_id]}
                        </TableCell>
                        <TableCell style={styleRow}>
                          {menuPlacesIndexed[row.menu_place_id]}
                        </TableCell>
                        <TableCell style={styleRow}>
                          <ToggleButton
                            value="check"
                            style={
                              !!row["active"]
                                ? ButtonStyle.defaultNoBorder
                                : ButtonStyle.buttonGray
                            }
                            onChange={() => {
                              setActiveItem(index);
                            }}
                          >
                            {!!row["active"] ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </ToggleButton>
                        </TableCell>
                        <TableCell style={styleRow}>
                          <Button
                            variant="contained"
                            style={ButtonStyle.default}
                            elevation={0}
                            onClick={() => {
                              setItemSelected(row);
                              setOpenMenu(true);
                            }}
                          >
                            Alterar
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Container>
      {openMenu ? (
        <DrawerMenu
          {...itemSelected}
          close={closeMenu}
          categories={props.categories.filter((item) => item.value !== TODOS)}
          menuPlaces={props.menuPlaces.filter((item) => item.value !== TODOS)}
        />
      ) : null}
    </Box>
  );
}

export default MenuScreen;
