import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loading from "../../components/Loading";
import ButtonStyle from "../../styles/button.style";
import Avatar from "@mui/material/Avatar";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  setActivePartners
} from "../../api/partnersController";
import { toast } from "react-toastify";
import { CircularProgress, ToggleButton } from "@mui/material";
import { HOTEL_UUID } from "../../utils/constants";

const columns = [
  { id: "icon", label: "Ícone", minWidth: 30 },
  { id: "name", label: "Nome", minWidth: 50 },
  //{ id: "description", label: "Sobre", minWidth: 50 },
  { id: "type", label: "Tipo", minWidth: 50 },
  { id: "site", label: "Site", minWidth: 50 },
  { id: "phones", label: "Telefones", minWidth: 200 },
  { id: "principal", label: "Destaque", minWidth: 50 },
  { id: "Status", label: "Status", minWidth: 50 },
  { id: "Editar", label: "", minWidth: 50 },
];

const styleRow = {
  fontSize: "14px",
  lineHeight: "20px",
  color: "#252733",
  fontWeight: "600",
};

function renderType(type) {
  if(type === "FOOD") {
    return "Alimentação";
  } else if(type === "TOUR") {
    return "Passeio";
  } else{
    return "Outros"
  }
}

const GridPartners = (props) => {

  const [loadingId, setLoadingId] = useState(null);

  const toogleActive = (partner_uuid, active) => {
    if(loadingId) return;

    setLoadingId(partner_uuid)
    setActivePartners(localStorage.getItem(HOTEL_UUID), partner_uuid, active)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingId(null);
        props.reload()
      })
      .catch((error) => {
        setLoadingId(null);
        toast.error(error.response.data.message);
      });
  }

  return (
    <>
      <Grid >
        <Grid item xs={12}>

          {props.loading &&
            <Loading color={"#4177F6"} text={"Carregando..."} />
          }

          {!props.loading && props.rows.length > 0 &&
            <Grid>
              <Paper sx={{ width: "100%" }}>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              backgroundColor: "#F5F6FA",
                              color: "#9FA2B4",
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {props.rows.map((row, index) => {
                        return (
                          <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            <TableCell style={styleRow}>
                              {row.icon ? (
                                <Avatar
                                  alt="Comida"
                                  variant="square"
                                  src={row.icon}
                                />
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    backgroundColor: "#E8EAEE",
                                    height: "48px",
                                    width: "48px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 4,
                                  }}
                                >
                                  <CameraAltRoundedIcon color="primary" />
                                </div>
                              )}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {/* name */}
                              {row.name}
                            </TableCell>
                            {/*
                              description
                            <TableCell style={styleRow}>
                              {row.description}
                            </TableCell>
                            */}
                            <TableCell style={styleRow}>
                              {/* tipo */}
                              {renderType(row.type)}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {/* site */}
                              {row.site}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {/* phones */}
                              {row.phones && row.phones.map(e => (
                                <p>{e.number}</p>
                              ))}
                            </TableCell>
                            <TableCell style={styleRow}>
                              {/* principal */}
                              {row.principal ? "Sim" : "Não"}
                            </TableCell>
                            <TableCell style={styleRow}>
                              <ToggleButton
                                value="check"
                                style={
                                  !!row["active"]
                                    ? ButtonStyle.defaultNoBorder
                                    : ButtonStyle.buttonGray
                                }
                                onChange={() => {
                                  toogleActive(row.id, !row.active);
                                }}
                              >
                                {loadingId && loadingId === row.id && <CircularProgress size={24}/>}
                                {loadingId !== row.id && (!!row.active ? (
                                  <VisibilityOutlinedIcon />
                                ) : (
                                  <VisibilityOffOutlinedIcon />
                                ))}
                              </ToggleButton>
                            </TableCell>
                            <TableCell style={styleRow}>
                              <Button
                                variant="contained"
                                style={ButtonStyle.default}
                                elevation={0}
                                onClick={() => {
                                  props.openEdit(row);
                                }}
                              >
                                Alterar
                              </Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          }
        </Grid>
      </Grid>
    </>
  );
};

export default GridPartners;
