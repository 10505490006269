import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SubTitle from "../../components/SubTitle";
import SchedulerIcon from "@mui/icons-material/Schedule";

import { HOTEL_UUID } from "./../../utils/constants";
import DrawerScheduler from "./DrawerScheduler";
import Title from "../../components/Title";
import GridScheduler from "./GridScheduler";
import DrawerSchedulerAvailable from "./DrawerSchedulerAvailable";

import { getAllSchedulerHotel } from "../../api/schedulerController";
import { formatDateEn } from "../../utils/dateUtils";

const AddButton = styled(Button)({
  textTransform: "none",
});

function SchedulerScreen(props) {
  const [openDrawerScherduler, setOpenDrawerScherduler] = React.useState(false);
  const [openSchedulerAvailable, setOpenSchedulerAvailable] =
    React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(null);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    reload();
  }, []);

  const close = (is_reload) => {
    setOpenDrawerScherduler(false);
    setOpenSchedulerAvailable(false);
    if (is_reload) {
      reload();
    }
  };

  const reload = () => {
    setLoading(true);
    getAllSchedulerHotel(localStorage.getItem(HOTEL_UUID))
      .then((resp) => {
        setRows(resp.data);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openEdit = (item) => {
    setItemSelected(item);
    setOpenDrawerScherduler(true);
  };

  const openScheduler = (item) => {
    setItemSelected(item);
    setOpenSchedulerAvailable(true);
  };

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Grid container sx={{ mt: 2, mb: 4 }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={10}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Title
              text="Serviços de agendamento"
              icon={<SchedulerIcon color="primary" />}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <AddButton
              variant="contained"
              color="primary"
              onClick={() => setOpenDrawerScherduler(true)}
              startIcon={<AddIcon />}
            >
              Adicionar
            </AddButton>
          </Grid>
        </Grid>

        <SubTitle
          text={`Cadastre todos os serviços 
        que são oferecidos ao hóspede como forma de agendamento.
        Ex.: agendamento de jacuzzi, agendamento de massagem, 
        atividades ao ar livre que necessitam de inscrição.`}
        />

        {
          <GridScheduler
            rows={rows}
            loading={loading}
            openScheduler={openScheduler}
            openEdit={openEdit}
          />
        }
      </Container>
      {openDrawerScherduler ? (
        <DrawerScheduler {...itemSelected} close={close} />
      ) : null}
      {openSchedulerAvailable ? (
        <DrawerSchedulerAvailable {...itemSelected} close={close} />
      ) : null}
    </Box>
  );
}

export default SchedulerScreen;
