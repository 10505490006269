import React, { useState } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider, MenuItem, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../components/SubTitle";
import { HOTEL_UUID } from "../../utils/constants";
import { toast } from "react-toastify";
import ButtonLoading from "../../components/layout/ButtonLoading";
import { createUpdateService, deleteService } from "../../api/servicesController";
import DialogConfirm from "../../components/DialogConfirm";

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#252733",
    letterSpacing: "0.4px",
    lineHeight: "24px",
  },
  local: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
  },
  date: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
    opacity: "0.54",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },

  data: {
    fontSize: 14,
    fontWeight: 700,
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
};

const drawerWidth = 600;

function DrawerActivities(props) {
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState(props.title || "");
  const [value, setValue] = useState(props.value || "");
  const [description, setDescription] = useState(props.description || "");
  const [activityId, setActivityId] = useState(props.id || null);
  const [type, setType] = useState(props.type || "");

  const [loadingButton, setLoadingButton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const save = async () => {
    setLoadingButton(true);

    let payload = {
      title: title,
      description: description,
      type: type,
      id: activityId,
      isActive: true
    };

    await createUpdateService(localStorage.getItem(HOTEL_UUID), payload)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
        setLoadingButton(false);
      })
  };

  const deleteItem = (responseOk) => {
    setOpenDelete(false);
    if (!responseOk) {
      return;
    }
    setLoadingButton(true);

    deleteService(localStorage.getItem(HOTEL_UUID), activityId)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
      })
      .catch(() => {
        toast.error("Erro, tente novamente mais tarde!");
        setLoadingButton(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Novo serviço</span>
      </div>
      <Divider />

      <form onSubmit={handleSubmit}>
        <div style={styles.box}>
          <SubTitle text="Informações do serviço" variant="subtitle2" />
          <div style={styles.boxAddPhone}>
            <TextField
              id="outlined-basic"
              label="Título"
              variant="outlined"
              placeholder="Título do serviço"
              required
              style={styles.itemBox}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              placeholder="descrição"
              required
              multiline
              rows={4}
              style={styles.itemBox}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div style={styles.dateContainer}>
              <Select
                sx={{ width: '35%' }}
                value={type}
                onChange={(type) => setType(type.target.value)}
                displayEmpty
              >
                <MenuItem value=""><em>Tipo</em></MenuItem>
                <MenuItem value={'COFFEE'}>Café/Lanche</MenuItem>
                <MenuItem value={'FOOD'}>Alimentação</MenuItem>
                <MenuItem value={'POOL'}>Intreterimento</MenuItem>
                <MenuItem value={'CHECKIN'}>Check-in</MenuItem>
                <MenuItem value={'CHECKOUT'}>Check-out</MenuItem>
                <MenuItem value={''}>Outros</MenuItem>
              </Select>
            </div>

            <ButtonLoading label="Salvar" loading={loadingButton} />

            {props.id ? (
              <ButtonLoading
                label="Excluir"
                loading={loadingButton}
                onClick={() => setOpenDelete(true)}
                delete
              />
            ) : null}

          </div>
        </div>
      </form>
      {openDelete && (
        <DialogConfirm
          onResponse={deleteItem}
          entity="esse serviço"
        />
      )}
    </SwipeableDrawer>
  );
}

export default DrawerActivities;
