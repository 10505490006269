import axios from 'axios';

export function addTicket(hotel_uuid, ticket) {
  return axios.post(`/api/v1/ticket`,
    {
      hotel_uuid: hotel_uuid,
      ticket: ticket,
      create_at: new Date(),
    }
  )
}

export function paginationTickets(hotelUID, lastDoc, rowsPerPage, next) {
  return axios.post(`/api/v1/ticket/${hotelUID}/pagination`,
    {
      item: lastDoc,
      rows_per_page: rowsPerPage,
      next: next,
    }
  )
}

export function getTopFiveTickects(hotel_uuid) {
  return axios.get(`/api/v1/ticket/five-top/${hotel_uuid}`)
}

export function getCountActivesTicketsHotel(hotel_uuid) {
  return axios.get(`/api/v1/ticket/count/actives/${hotel_uuid}`)
}
