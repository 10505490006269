import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
const dog = require('../../assets/ops-dog.png')

export default function NotOrders() {
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <img src={dog} height={116} width={207} alt="Fives" />
        <h1 style={{ fontSize: 14, maxWidth: '30%', textAlign: 'center' }}>Você não tem pedidos por enquanto!</h1>
      </Box>
    </Container>
  )
}