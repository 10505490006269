import axios from 'axios';

export function getActivities(hotel_uuid) {
  return axios.get(`/api/v1/activity/${hotel_uuid}`)
}

export function createUpdateActivity(hotel_uuid, payload) {
  return axios.post(`/api/v1/activity/${hotel_uuid}`,
    payload
  )
}

export function deleteActivity(hotel_uuid, activity_id, day_of_week_id) {
  return axios.delete(`/api/v1/activity/${hotel_uuid}/${activity_id}/${day_of_week_id}`)
}
