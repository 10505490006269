import axios from "axios";

export function getMenuCatergories() {
  return axios.get("/api/v1/menu/categories");
}

export function getMenuPlaces(hotel_uuid) {
  return axios.get(`/api/v1/menu/menuplaces/${hotel_uuid}`);
}

export function getMenuProducts(hotel_uuid) {
  return axios.get(`/api/v1/menu/products/${hotel_uuid}`);
}

export function saveMenuProducts(item_uuid, hotel_uuid, item) {
  if (item_uuid) {
    return axios.put(`/api/v1/menu/products/${hotel_uuid}/${item_uuid}`, item);
  } else {
    return axios.post(`/api/v1/menu/products/${hotel_uuid}`, item);
  }
}

export function changeStatusProduct(item_uuid, hotel_uuid, active) {
  return axios.put(
    `/api/v1/menu/products/${hotel_uuid}/${item_uuid}/${active}`
  );
}

export function deleteProduct(item_uuid, hotel_uuid) {
  return axios.delete(`/api/v1/menu/products/${hotel_uuid}/${item_uuid}`);
}
