import React from 'react';
import { Box } from '@mui/material';
import { formatDatePtBr } from '../../utils/dateUtils';

const styles = {
  title: {
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '19px',
    color: '#252733',
    letterSpacing: '0.4px',
    lineHeight: '24px'
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.0015em',
    color: '#252733'
  },
  description: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#707070'
  },
  createAt: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: 20
  },
  createBy: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
  },
  readBy: {
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    border: '1px solid #DFE0EB',
    backgroundColor: "#F5F5F5"
  },
}

function BoxItemNotification(props) {
  return (
    <Box
      component="div"
      sx={styles.boxColumn}
    >
      {/* <span style={styles.subtitle}>
        {props.title}
      </span>
      <span style={styles.description}>
        {props.description}
      </span>

      <div style={{ display: "flex", flexDirection: "column", justifyContent: 'end', alignItems: 'flex-end', width: '100%' }}>
        <span style={styles.createAt}>
          Criada em: {formatDatePtBr(new Date(props.create_at))}
        </span>
        <span style={styles.createBy}>
          Criada por: {props.create_by}
        </span>
      </div> */}
    </Box>
  );
}

export default BoxItemNotification;