import React from "react";
import { Skeleton, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect } from "react";

function DaysOfWeek(props) {
  const [daysOfWeek, setDaysOfWeek] = React.useState([
    "dom",
    "seg",
    "ter",
    "qua",
    "qui",
    "sex",
    "sáb",
  ]);

  const handleChange = (event) => {
    if (event.target.checked) {
      props.addDaysOfWeek(event.target.value);
    } else {
      props.delDaysOfWeek(event.target.value);
    }
    setDaysOfWeek([...daysOfWeek]); // force updated
  };

  useEffect(() => { }, [daysOfWeek]);

  const renderComponents = () => {
    return daysOfWeek.map((item, index) => {
      let checked = props.days_of_week.findIndex((i) => i === index) !== -1;
      return (
        <FormControlLabel
          key={item}
          control={
            <Checkbox checked={checked} value={index} onChange={handleChange} />
          }
          label={item}
        />
      );
    });
  };
  return props.loading ? (
    <Skeleton variant="rect" width={260} height={134} sx={{ mt: 2, borderRadius: '4px', backgroundColor: '#E8EAEE' }} />
  ) : (
    <FormGroup
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      {renderComponents()}
    </FormGroup>
  );
}

export default DaysOfWeek;
