import { USER_DATA_KEY } from './utils/constants';
import axios from 'axios'

// Web Push notification
const publicVapidKey = 'BPjzdOtLUBCm7OyFmtEoLNAp5-txD9RCCJt5iyqOHAMM8Ya9t0BKhSt5agkMq3az508fCnTaetQ3K_Rl7IHNc7U';

const URL_BASE = "https://webpush.fivesapp.com.br" //process.env.NODE_ENV === 'production' ? "https://webpush.fivesapp.com.br/subscribe" : 'http://localhost:3000/subscribe';

const instance = axios.create({
  baseURL: URL_BASE,
  //timeout: 2000,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
    "api-key": "BPjzdOtLUBCm7OyFmtEoLNAp5-txD9RCCJt5iyqOHAMM8Ya9t0BKhSt5agkMq3az508fCnTaetQ3K_Rl7IHNc7U"
  },
});

//register the service worker, register our push api, sedn the notifation
export async function enableWebPushNotification(){
    //check if the service worker can work in the current browser
    if('serviceWorker' in navigator){      
      //register service worker
      const register = await navigator.serviceWorker.register(`${process.env.PUBLIC_URL}worker.js`, {
          scope: '/'
      });
  
      if (Notification.permission === "denied") {
        console.log("Usuário não permitiu o push notification ;(");
        return;
      }

      if (Notification.permission === "default") {
        const userData = localStorage.getItem(USER_DATA_KEY);
        let hotelUID;
        let userUID;
        if (userData) {
          //'{"email":"conceicao.roberto@gmail.com","role":"USER","hotelUUID":"f5ZAGGPA7Sz1WTjZtiGg","name":"Bob","firebaseUUID":"RdAqsboufNNR9GKOs6OKgtn1EH33"}'
          const userDataParsed = JSON.parse(userData);
          hotelUID = userDataParsed.hotel_uuid;
          userUID = userDataParsed.firebaseUUID;
        }
        //register push
        console.log('Regikstering push...')
        const subscription = await register.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicVapidKey)
        });
      
        const data = {
          hotelUID: hotelUID,
          userUID: userUID,
          subscription: subscription
        }
        
        //Send push notification
        instance.post('/subscribe',data)
      }
    }
}

function urlBase64ToUint8Array(base64String) {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, "+")
      .replace(/_/g, "/");
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}