import React from 'react';
import { Button, Box, CircularProgress } from '@mui/material';

const deleteStyle = {
  marginTop: '20px',
  width: '100%',
  color: '#FF0000',

}
const defaultStyle = {
  marginTop: '20px',
  width: '100%',
  height: '48px',
  borderRadius: '8px'
}

function ButtonLoading (props) {
  
  return <Box sx={{position: 'relative' }}>
    <Button
      variant={props.delete ? 'text' : 'contained'}
      elevation={0}
      style={props.delete ? deleteStyle : defaultStyle}
      type={props.onClick ? "button" : "submit"}
      disabled={props.loading || props.disabled}
      onClick={() => {
        if(props.onClick) {
          props.onClick()
        }
      }}
    >
      {props.label}
    </Button>
    {props.loading && (
      <CircularProgress
        size={24}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginLeft: '-12px',
        }}
      />
    )}
</Box>
}

export default ButtonLoading