import React, { useState } from "react";
import { SwipeableDrawer, Divider, IconButton, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import { formatDatePtBr } from "../utils/dateUtils";

const styles = {
  title: {
    //fontFamily: 'Poppins',
    fontWeight: "600",
    fontSize: "19px",
    lineHeight: "28px",
    letterSpacing: "0.4px",
    color: "#252733",
  },
  subTitle: {
    //fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    letterSpacing: "0.1px",
    color: "#252733",
    opacity: "0.5",
  },
  textSuggestion: {
    //fontFamily: 'Poppins',
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    color: "#000000",
    opacity: "0.5",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px",
  },
  box: {
    padding: "12px",
    background: "#E8EAEE",
    width: "100%",
  },
  padding: {
    padding: "12px",
  },
};

const drawerWidth = 405;

const recomendationLevel = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const starLevel = ["1", "2", "3", "4", "5"];

const renderRecomendationNote = (noteNPS) => {
  return recomendationLevel.map((item, index) => (
    <div
      key={index}
      style={{
        background: item <= noteNPS ? "#EEF1F5" : "#FFFFFF",
        border:
          item <= noteNPS
            ? "1px solid rgba(65, 119, 246, 0.5)"
            : "1px solid #C4C4C4",
        width: "34px",
        height: "34px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          fontWeight: "500",
          fontSize: "12px",
          lineHeight: "22px",
          color: item <= noteNPS ? "#4177F6" : "#C4C4C4",
        }}
      >
        {item}
      </div>
    </div>
  ));
};

const renderStarLevel = (note) => {
  return (
    <div>
      {starLevel.map((item, index) => (
        <StarIcon
          key={index}
          sx={{
            color: item <= note ? "#4177F6" : "#C4C4C4",
          }}
        />
      ))}
    </div>
  );
};

const DividerCustom = styled(Divider)(({ theme }) => ({
  width: "95%",
  margin: "0 auto",
}));

function DrawerRating(props) {
  const [open, setOpen] = useState(true);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Visualizar avaliação</span>
      </div>

      <div style={styles.box}>
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Hóspede</span>
          <span>{props.user}</span>
        </div>
        <DividerCustom />
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Quarto</span>
          <span>{props.room}</span>
        </div>
        <DividerCustom />
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Período</span>
          <span>
            {props["timestamp_to_date"] &&
              formatDatePtBr(new Date(props["timestamp_to_date"]))}
          </span>
        </div>
      </div>

      <div style={styles.padding}>
        <div style={styles.row}>
          <span style={styles.title}>Avaliação</span>
        </div>

        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Estacionamento</span>
          {renderStarLevel(props.parkingRating)}
        </div>
        <DividerCustom />
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Limpeza</span>
          {renderStarLevel(props.cleaningRating)}
        </div>
        <DividerCustom />
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Atendimento</span>
          {renderStarLevel(props.treatmentRating)}
        </div>
        <DividerCustom />
        <div style={styles.rowBetween}>
          <span style={styles.subTitle}>Recreação</span>
          {renderStarLevel(props.recreationRating)}
        </div>
        <DividerCustom />
        <div style={styles.row}>
          <span style={styles.subTitle}>
            Quanto você recomendaria nosso hotel para amigos e família?
          </span>
        </div>

        <div style={styles.row}>{renderRecomendationNote(props.noteNPS)}</div>
        <div style={styles.rowBetween}>
          <span
            style={{
              color: "#FF3A44",
              opacity: "0.5",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Pouco
          </span>
          <span
            style={{
              color: "#2DA771",
              opacity: "0.5",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            Muito
          </span>
        </div>
        <DividerCustom />
        <div style={styles.row}>
          <span style={styles.textSuggestion}>Sugestão enviada</span>
        </div>
        <div style={{ padding: "12px" }}>{props.comment}</div>
      </div>
    </SwipeableDrawer>
  );
}

export default DrawerRating;
