import * as React from 'react';
import { Button } from '@mui/material';

function Status(props) {

  const Status = {
    ACTIVE: "ACTIVE", // já está no hotel
    CONFIRMED: "CONFIRMED", // ja fex precheckin
    WAITING: "WAITING", // agauradando precheckin
    EXPIRED: "EXPIRED",
    CANCELED: "CANCELED",
  }

  function getTextStatus(status) {
    switch (status) {
      case Status.ACTIVE:
        return 'Ativo';
      case Status.CONFIRMED:
        return 'Confirmado';
      case Status.WAITING:
        return 'Aguardando';
      case Status.EXPIRED:
        return 'Expirado';
      case Status.CANCELED:
        return 'Cancelado';
    }
  }

  function getTagStatus(status) {
    switch (status) {
      case Status.ACTIVE:
        return '#007942';
      case Status.CONFIRMED:
        return '#4177F6';
      case Status.WAITING:
        return '#F09000';
      case Status.EXPIRED:
        return '#999999';
      case Status.CANCELED:
        return '#FF3A44';
    }
  }

  return (
    <Button
      style={{
        height: '24px',
        borderRadius: '100px',
        background: getTagStatus(props.status),
      }}
    >
      <span
        style={{ color: '#FFFFFF' }}
      >{getTextStatus(props.status)}</span>
    </Button>
  );
}

export default Status;