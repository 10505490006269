import React, {useState} from 'react';
import { SwipeableDrawer, IconButton, Divider} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ClearIcon from '@mui/icons-material/Clear';
import { toast } from 'react-toastify';
import TextStyle from '../../styles/text.style';
import TableStyle from '../../styles/table.style';
import InputFileImage from '../../components/inputfile/InputFileImage';
import SubTitle from '../../components/SubTitle';
import { uploadPhotosHotel } from '../../api/hotelController';
import {HOTEL_UUID} from '../../utils/constants';
import ButtonLoading from '../../components/layout/ButtonLoading';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';

const styles = {
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
  },
  rowConfirm: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '12px',
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '20px'
  }
}

const drawerWidth = 600;

function DrawerPhotos (props) {
  const [open, setOpen] = useState(true);
  const [photos, setPhotos] = useState(props['photos'] || []);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [file, setFile] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  
    setOpen(open);
    props.close()
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('photos', JSON.stringify(photos));
    
    await photos.forEach((item, index) => {
      form.append(`${index}`, item.file);
    });

    setLoading(true);
    uploadPhotosHotel(localStorage.getItem(HOTEL_UUID), form)
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        props.close(true)
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const addPhoto = (e) => {
    e.preventDefault();
    photos.push({
      photo: photo,
      main: false,
      file: file,
    })
    setPhotos([...photos])
    setPhoto(null)
    setFile(null)
  }

  const delPhoto = (index) => {
    let deletePhotoMain = photos[index].main
    photos.splice(index, 1)

    if (deletePhotoMain) {
      checkMain(0)
    } else {
      setPhotos([...photos])
    }
  }

  const checkMain = async (index) => {
    await photos.forEach((element, i) => {
      element.main = index === i
    });
    setPhotos([...photos])
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex:'1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon/>
          </IconButton>
        </div>
        <span style={TextStyle.title}>
          Editar imagens
        </span>
      </div>
      <Divider />

      <form onSubmit={addPhoto} name='form-add'>
        <div style={{ paddingLeft: '20px', paddingRight: '20px'}}>
          <SubTitle text="Imagens" variant="subtitle2"/>
          {photos.length < 4 ? <InputFileImage setIcon={setPhoto} icon={photo} setFile={setFile}>
            <div style={styles.rowConfirm}>
              <Button
                type='submit'
                variant='contained' 
                color={"primary"}
                elevation={0}
                startIcon={<CheckIcon />} 
                fullWidth
                sx={{textTransform: 'none'}}
                disabled={!photo}
              >
                Confirmar
              </Button>
            </div>
          </InputFileImage> :
          <Box
            sx={{ 
              border: '1px solid #C4C4C4',
              backgroundColor: '#EEF1F5',
              borderRadius: '4px',
              p: 2,
            }}
            >  
            Você atingiu o limite máximo de fotos, caso queira alterar alguma,
            clique no x da linha que contém a foto que desejas substituir.
          </Box>
          }
        </div>
      </form>
      
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div style={styles.box}>
          <SubTitle text="Editar" variant="subtitle2" />
          
          <TableContainer sx={{ maxHeight: 440, marginTop: "10px" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={TableStyle.head}
                  >
                    Destaque
                  </TableCell>
                  <TableCell
                    align="left"
                    style={TableStyle.head}
                  >
                    Imagem
                  </TableCell>
                  <TableCell
                    align="center"
                    style={TableStyle.head}
                  >
                    Númeração                  
                  </TableCell>
                  <TableCell
                    align="center"
                    style={TableStyle.head}
                  >
                    Edição
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {photos
                  .map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell style={TableStyle.textRow} align="center">
                          <Checkbox
                            icon={<StarBorderIcon />} 
                            checkedIcon={<StarIcon />}
                            checked={row.main}
                            onChange={() => checkMain(index)}
                          />
                        </TableCell>
                        <TableCell style={TableStyle.textRow}>
                          <Avatar
                            alt="Comida" 
                            variant="rounded"
                            src={row.photo}
                          />
                        </TableCell>
                        <TableCell style={TableStyle.textRow} align="center">
                          {index}
                        </TableCell>
                        <TableCell style={TableStyle.textRow} align="center">
                          <ClearIcon 
                            color="primary" 
                            sx={{
                              background: "#E8EAEE",
                              borderRadius: "2px"
                            }}
                            onClick={() => delPhoto(index)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>

          <ButtonLoading
            label="Salvar"
            loading={loading}
          />
        </div>
      </form>
    </SwipeableDrawer>
  )
}

export default DrawerPhotos;