import React, { useState } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import TextStyle from "../../styles/text.style";
import InputFileImage from "../../components/inputfile/InputFileImage";
import SubTitle from "../../components/SubTitle";
import { saveMenuProducts, deleteProduct } from "../../api/menuController";
import { HOTEL_UUID, currencyConfig } from "../../utils/constants";
import ButtonLoading from "../../components/layout/ButtonLoading";

import IntlCurrencyInput from "react-intl-currency-input";
import { convertStringToNumber, formatFloat } from "./../../utils/stringUtils";
import DialogConfirm from "../../components/DialogConfirm";

const styles = {
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "20px",
  },
};

const drawerWidth = 600;

function DrawerMenu(props) {
  const [open, setOpen] = useState(true);
  const [icon, setIcon] = useState(props["icon"] || "");
  const [title, setTitle] = useState(props["title"] || "");
  const [description, setDescription] = useState(props["description"] || "");
  const [value, setValue] = useState(formatFloat(props["value"]) || "");
  const [categoryId, setCategoryId] = useState(props["category_id"] || "");
  const [menuPlaceId, setMenuPlaceId] = useState(props["menu_place_id"] || "");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const listCategory = props.categories;
  const listMenuPlaces = props.menuPlaces;
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("title", title);
    form.append("description", description);
    form.append("value", convertStringToNumber(value));
    form.append("category_id", categoryId);
    form.append("menu_place_id", menuPlaceId);
    form.append("file", file);
    form.append("icon", icon);

    setLoading(true);
    saveMenuProducts(props.id, localStorage.getItem(HOTEL_UUID), form)
      .then((response) => {
        toast.success("Produto adicionado com sucesso");
        props.close(true);
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteItem = (responseOk) => {
    setOpenDelete(false);
    if (!responseOk) {
      return;
    }

    setLoading(true);
    deleteProduct(props.id, localStorage.getItem(HOTEL_UUID))
      .then((response) => {
        toast.success("Produto excluído com sucesso");
        props.close(true);
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const valueChange = (event, value, maskedValue) => {
    event.preventDefault();
    setValue(value);
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={TextStyle.title}>
          {props.id ? "Atualizar produto" : "Adicionar produto"}
        </span>
      </div>
      <Divider />

      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <SubTitle text="Imagem" variant="subtitle2" />
        <InputFileImage setIcon={setIcon} icon={icon} setFile={setFile} />
      </div>

      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div style={styles.box}>
          <SubTitle text="Informações" variant="subtitle2" />
          <TextField
            id="outlined-basic"
            label="Título"
            variant="outlined"
            required
            style={styles.itemBox}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Descrição"
            variant="outlined"
            required
            multiline
            rows={4}
            placeholder="Descreva sua notificação aqui"
            style={styles.itemBox}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <TextField
            select
            label="Categoria"
            style={styles.itemBox}
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
          >
            {listCategory.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            select
            label="Local"
            style={styles.itemBox}
            value={menuPlaceId}
            onChange={(e) => setMenuPlaceId(e.target.value)}
          >
            {listMenuPlaces.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <IntlCurrencyInput
            component={TextField}
            currency="BRL"
            config={currencyConfig}
            onChange={valueChange}
            value={value}
          />

          <ButtonLoading label="Salvar produto" loading={loading} />

          {props.id ? (
            <ButtonLoading
              label="Excluir"
              onClick={() => setOpenDelete(true)}
              delete
            />
          ) : null}
        </div>
      </form>
      {openDelete && (
        <DialogConfirm
          onResponse={deleteItem}
          entity="esse produto"
        />
      )}
    </SwipeableDrawer>
  );
}

export default DrawerMenu;
