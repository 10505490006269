export default {
  DASHBOARD: "dashboard_controller",
  MENU_PERMISSION: "menu_controller",
  REQUEST_PERMISSION: "request_controller",
  INFORMATIONS_PERMISSION: "informations_controller",
  CHAT_PERMISSION: "chat_controller",
  SCHEDULER: "scheduler_controller",
  ACTIVITY_CONTROLLER: "activity_controller",
  SERVICES_CONTROLLER: "services_controller",
  PARTNERS_CONTROLLER: "partners_controller",
  GUESTS_CONTROLLER: "guests_controller",
};
