import React from 'react';
import Box from '@mui/material/Box';
import NotOrders from './NotOrders'

import style from './Orders.style'
import Loading from '../../components/Loading';
import OrderBoard from './OrderBoard';

function OrdersScreen(props) {

  return (
    <Box
      component="div"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      {props.loading
        ?
        <Loading color={'#4177F6'} text={'Carregando...'} />
        :
        <>
          <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
            <OrderBoard orders={props.createdOrders} title={'Pedidos em aberto'} count={props.createdOrders.length} />
            <OrderBoard orders={props.confirmedOrders} title={'Pedidos na cozinha'} count={props.confirmedOrders.length} />
            <OrderBoard orders={props.doneTodayOrders} title={'Pedidos finalizados no dia'} count={props.doneTodayOrders.length} />
          </div>

          {props.createdOrders.length == 0 &&
            props.confirmedOrders.length == 0 &&
            props.doneTodayOrders.length == 0 &&
            <div style={style.emptyStateContainer}>
              <NotOrders />
            </div>
          }
        </>
      }

    </Box>
  )
}

export default OrdersScreen;