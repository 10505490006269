import React, {useState, useEffect} from 'react';
import { HOTEL_UUID } from '../../utils/constants';
import MenuDashboardScreen from './MenuDashboard.screen';
import { getMenuProducts, getMenuCatergories, getMenuPlaces } from '../../api/menuController';
import { TODOS } from '../../utils/constants';

export default function MenuContainer() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [categories, setCategories] = useState([]);
  const [menuPlaces, setMenuPlaces] = useState([]);
  
  useEffect(()=> {
    getMenuCatergories()
    .then(response => {
      let loadCategories = [{ value: TODOS, label: "Todas" }]
      setCategories(loadCategories.concat(response.data))
    })
    getMenuPlaces(localStorage.getItem(HOTEL_UUID))
    .then(response => {
      let loadMenuPlaces = [{ value: TODOS, label: "Todos" }]
      setMenuPlaces(loadMenuPlaces.concat(response.data))
    })
    reload();
  },[]);

  const reload = () => {
    setLoading(true)
    getMenuProducts(localStorage.getItem(HOTEL_UUID))
      .then(response => {
        setRows(response.data);
      })
      .catch(err => {
        console.log('err: ', err);
      })
      .finally(() => {
        setLoading(false)
      });
  }

  return (
    <MenuDashboardScreen
      loading={loading}
      rows={rows}
      reload={reload}
      categories={categories}
      menuPlaces={menuPlaces}
    />
  )    
}