import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { numberToRealBR } from '../../utils/stringUtils'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SubTitle from '../../components/SubTitle'
import { formatDateTimePtBr } from '../../utils/dateUtils'
import style from './Orders.style'
import { HOTEL_UUID } from '../../utils/constants';
//import { getDatabase, ref, onValue } from "firebase/database";
import { Button, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import DrawerOrderDetails from './DrawerOrderDetails';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import SearchIcon from "@mui/icons-material/Search";
import { DebounceInput } from 'react-debounce-input';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "dayjs/locale/pt-br";
const dayjs = require('dayjs')

const columns = [
    { id: 'client', label: 'Cliente', maxWidth: 50 },
    { id: 'date', label: 'Dia/Hora', maxWidth: 50 },
    { id: 'room', label: 'Quarto', maxWidth: 30 },
    { id: 'order_id', label: 'Pedido', maxWidth: 40 },
    { id: 'amount', label: 'Valor', maxWidth: 40 },
    { id: 'local', label: 'Local', maxWidth: 50 },
    { id: 'status', label: 'Status', maxWidth: 10 },
    { id: 'details', label: '', minWidth: 0 },
];

function OrderHistory(props) {

    const [doneOrders, setDoneOrders] = useState(null);
    const [loading, setLoading] = useState(true);
    const [itemSelected, setItemSelected] = React.useState(null);
    const [openRequest, setOpenRequest] = React.useState(false);

    const [items, setItems] = React.useState([]);

    const [search, setSearch] = React.useState(null);
    const [dateInit, setDateInit] = React.useState(dayjs(new Date()));
    const [dateFinal, setDateFinal] = React.useState(dayjs(new Date()));

    const [roomEquals, setRoomEquals] = React.useState(true);
    const [totalComand, setTotalComand] = React.useState(0);
    const [roomSearched, setRoomSearched] = React.useState(0);

    useEffect(() => {
        setLoading(true)
/*
        const starCountRef = ref(getDatabase(), `orders/${localStorage.getItem(HOTEL_UUID)}`);
        onValue(starCountRef, async (snapshot) => {
            await getItem(snapshot.val())
        }, { onlyOnce: true })
*/
    }, [])

    useEffect(() => {

        if (doneOrders) {

            let filtered = doneOrders

            if (search) {
                filtered = filtered.filter(
                    (i) =>
                        i.room.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                        i.user_name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                );
            }

            const date1 = dayjs(dateInit).format("YYYY-MM-DD");
            const date2 = dayjs(dateFinal).format("YYYY-MM-DD");

            filtered = filtered.filter((i) => {

                const createdAt = dayjs(i.createdAt).format("YYYY-MM-DD");

                return createdAt === date1 || createdAt === date2
                    || dayjs(createdAt).isBetween(date1, date2)
            });

            let room = filtered[0]?.room
            setRoomSearched(room)

            let total = 0

            filtered.forEach((element, index) => {
                if (element.room == room) {
                    total = total + element.total
                    setRoomEquals(true)
                } else {
                    setRoomEquals(false)
                }
            });

            setTotalComand(total)
            setItems(filtered);
        }

    }, [search, dateInit, dateFinal, doneOrders]);

    function getItem(data) {

        setDoneOrders([])
        let doneOrders = []

        for (const [key, value] of Object.entries(data)) {
            for (const [key2, value] of Object.entries(data[key])) {
                // if (value.status == 'done') {
                doneOrders.push(value)
                // }
            }
        }

        doneOrders.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setDoneOrders([...doneOrders])
        setItems([...doneOrders])
        setLoading(false)
    }

    const renderStatus = (status) => {

        let text = ''
        let color = ''

        if (status == 'created') {
            text = 'ABERTO'
            color = '#FF9C00'
        }

        if (status == 'confirmed') {
            text = 'NA COZINHA'
            color = '#4177F6'
        }

        if (status == 'done') {
            text = 'FINALIZADO'
            color = '#2DA771'
        }

        return (
            <div style={{ marginBottom: 10, paddingTop: 2, paddingBottom: 2, backgroundColor: color, borderRadius: 20 }}>
                <span style={style.statusText}>{text}</span>
            </div>
        )
    };

    const showRequest = () => {
        setOpenRequest(true);
    }

    const closeRequest = () => {
        setOpenRequest(false);
        setItemSelected(null);
    }

    const styles = {
        title: {
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: '28px',
            textAlign: 'center',
            letterSpacing: '1px',
            color: '#4177F6',
            marginLeft: '10px'
        },
        subtitle: {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            textAlign: 'center',
            letterSpacing: '0.4px',
            color: '#979797',
            paddingRight: '5px',
            width: '80%',
        }
    }

    return (

        <Box maxWidth="xl" sx={{ mb: 5, ml: 1, mt: 3 }}>

            <Grid container sx={{ mt: 2, mb: 4, zIndex: 1, alignItems: "center" }} spacing={2}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <FormControl fullWidth>
                        <InputLabel htmlFor="outlined-search" size="small">
                            O que voçê procura?
                        </InputLabel>

                        <DebounceInput
                            id="outlined-search"
                            value={search}
                            minLength={1}
                            label="Busca"
                            debounceTimeout={300}
                            onChange={(e) => setSearch(e.target.value)}
                            element={OutlinedInput}
                            size="small"
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DatePicker
                            localeText={"pt-br"}
                            label="Período de"
                            value={dateInit}
                            format="DD/MM/YYYY"
                            onChange={(newValue) => setDateInit(new Date(newValue))}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={2}
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                        <DatePicker
                            localeText={"pt-br"}
                            label="Período até"
                            value={dateFinal}
                            format="DD/MM/YYYY"
                            onChange={(newValue) => setDateFinal(new Date(newValue))}
                            slotProps={{ textField: { size: 'small' } }}
                        />
                    </LocalizationProvider>
                </Grid>

                <Grid item md={4} style={{ textAlign: 'center', justifyContent: 'center', width: '100%', flexDirection: 'Row', display: 'flex' }}>
                    <div style={{ alignItems: 'center', justifyContent: 'cneter', display: 'flex' }}>
                        <span style={styles.subtitle}>{roomEquals ? `Quarto único encontrado (${roomSearched}): ` : 'Pesquise um quarto único para ver o total gasto pelo hóspede'}</span>
                        <span style={styles.title}>{roomEquals ? numberToRealBR(totalComand) : ''}</span>
                    </div>
                </Grid>
            </Grid>

            <SubTitle text={`Histórico de pedidos`} />

            <Paper sx={{ width: '100%' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={'left'}
                                        style={{
                                            maxWidth: column.maxWidth,
                                            backgroundColor: '#F5F6FA',
                                            color: '#9FA2B4',
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, index) => {
                                return (
                                    <TableRow hover tabIndex={-1} key={index}>
                                        <TableCell style={style.styleRow}>
                                            {row.user_name}
                                        </TableCell>
                                        <TableCell style={style.styleRow}>
                                            {formatDateTimePtBr(new Date(row.createdAt))}
                                        </TableCell>
                                        <TableCell style={style.styleRow}>
                                            {row.room}
                                        </TableCell>
                                        <TableCell style={style.styleRow}>
                                            #{row.order_id}
                                        </TableCell>
                                        <TableCell style={style.styleRow}>
                                            {numberToRealBR(row.total)}
                                        </TableCell>
                                        <TableCell style={style.styleRow}>
                                            {row.local}
                                        </TableCell>
                                        <TableCell style={{
                                            fontSize: '14px',
                                            lineHeight: '20px',
                                            color: '#252733',
                                            fontWeight: '600',
                                            maxWidth: 75,
                                            textAlign: 'center',
                                        }}>
                                            {renderStatus(row.status)}
                                        </TableCell>
                                        <TableCell align="right" style={style.styleRow}>
                                            <Button
                                                variant='contained'
                                                style={style.styleButton}
                                                elevation={0}
                                                onClick={() => {
                                                    setItemSelected(row)
                                                    showRequest()
                                                }}
                                            >
                                                Detalhes
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            {openRequest ? <DrawerOrderDetails order={itemSelected} close={closeRequest} /> : null}
        </Box>
    )
}

export default OrderHistory;