import React, {useState} from 'react';
import { SwipeableDrawer, IconButton, TextField, 
  Divider} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TextStyle from '../../styles/text.style';
import SubTitle from '../../components/SubTitle';
import ButtonLoading from '../../components/layout/ButtonLoading';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableStyle from '../../styles/table.style';
import Button from '@mui/material/Button';
import CheckIcon from '@mui/icons-material/Check';
import { PatternFormat } from 'react-number-format';
import { WHATSAPP, PHONE, HOTEL_UUID } from '../../utils/constants';
import { toast } from 'react-toastify';
import { saveHotelPhones } from '../../api/hotelController';

const styles = {
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: '12px',
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  },
  boxAddPhone: {
    padding: '20px',
    width: '100%',
    background: '#E8EAEE',
    border: '1px solid #C4C4C4',
    borderRadius: '4px'
  }
}

const drawerWidth = 600;

function DrawerPhones (props) {
  const [open, setOpen] = useState(true);
  const [phones, setPhones] = useState(props['phones'] || []);
  const [title, setTitle] = useState('');
  const [number, setNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
  
    setOpen(open);
    props.close()
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    saveHotelPhones(localStorage.getItem(HOTEL_UUID), {
      phones: phones
    })
      .then(response => {
        toast.success("Dados atualizado com sucesso")
        props.close(true)
      })
      .catch((e) => {
        toast.error("Ocorreu um problema, tente mais tarde!")
      }).finally(() => {
        setLoading(true);
      })
  };

  const addPhones = (e) => {

    if(!number || !title) {
      return
    }
    e.preventDefault();
    phones.push({
      contact_title: title,
      number: number,
      type: PHONE
    })
    setTitle("")
    setNumber("")
    setPhones([...phones])
  }

  const delPhones = (index) => {
    phones.splice(index, 1)
    setPhones([...phones])
  }

  const checkWhatsapp = (e, index) => {
    phones[index].type = e.target.checked ? WHATSAPP : PHONE
    setPhones([...phones])
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex:'1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      >
        <div style={styles.row}>
          <div style={styles.buttonClose}>
            <IconButton
              component="span"
              onClick={toggleDrawer(false)}>
              <CloseIcon/>
            </IconButton>
          </div>
          <span style={TextStyle.title}>
            Editar telefones
          </span>
        </div>
        <Divider />

        <form onSubmit={addPhones} name='form-add'>
          <div style={{ paddingLeft: '20px', paddingRight: '20px'}}>
            <SubTitle text="Adicionar número de telefone" variant="subtitle2"/>
            <div style={styles.boxAddPhone}>
              <div style={styles.rowBetween}>
                <TextField 
                  id="outlined-basic" 
                  label="Título"
                  variant="outlined"
                  placeholder="Título para o número"
                  required
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                
                <PatternFormat 
                  id="outlined-basic" 
                  customInput={TextField}
                  label="Número"
                  variant="outlined"
                  placeholder="(xx) xxxxx-xxxx"
                  required
                  format="(##) #####-####"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
              <div style={styles.row}>
                <Button
                  type='submit'
                  variant='contained' 
                  color="primary"
                  elevation={0}
                  startIcon={<CheckIcon />} 
                  fullWidth                  
                >
                  Confirmar
                </Button>
              </div>
            </div>
          </div>
        </form>

        <form onSubmit={handleSubmit} name='form-phones'>
          <div style={styles.box}>
            <SubTitle text="Editar números de telefones" variant="subtitle2"/>
            <TableContainer sx={{ maxHeight: 440, marginTop: "10px" }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={TableStyle.head}
                    >
                      Título
                    </TableCell>
                    <TableCell
                      align="left"
                      style={TableStyle.head}
                    >
                      Número                  
                    </TableCell>
                    <TableCell
                      align="left"
                      style={TableStyle.head}
                    >
                      Whatsapp
                    </TableCell>
                    <TableCell
                      align="left"
                      style={TableStyle.head}
                    >
                      Edição
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {phones
                    .map((row, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell style={TableStyle.textRow}>
                            {row.contact_title}
                          </TableCell>
                          <TableCell style={TableStyle.textRow}>
                            {row.number}
                          </TableCell>
                          <TableCell style={TableStyle.textRow} align="center">
                            <Checkbox
                              checked={row.type === WHATSAPP}
                              onChange={(e) => checkWhatsapp(e, index)}
                            />
                          </TableCell>
                          <TableCell style={TableStyle.textRow} align="center">
                            <CloseIcon
                              color="primary" 
                              sx={{
                                background: "#E8EAEE",
                                borderRadius: "2px"
                              }}
                              onClick={() => delPhones(index)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
            
            <ButtonLoading
              label="Salvar"
              loading={loading}
            />
          </div>
        </form>
    </SwipeableDrawer>
  )
}

export default DrawerPhones;