import React, { useState } from 'react';
import { SwipeableDrawer, IconButton, TextField, Button, Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/PrintOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { convertStringToDatePTBR } from '../../../utils/dateUtils';
import { USER_DATA_KEY } from '../../../utils/constants';
import { editPrecheckin, printPDF } from '../../../api/precheckinController';
import { Navigation } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { HOTEL_UUID } from "../../../utils/constants";

const styles = {
  title: {
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  subtitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.0015em',
    color: '#252733',
    paddingTop: 10,
    paddingBottom: 10,
  },
  dependentName: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.0015em',
    color: '#000',
  },
  createAt: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: '0.2px',
    color: '#9FA2B4',
    marginTop: 20
  },

  textSuggestion: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    opacity: '0.5'
  },
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px',
  },
  rowBetweenHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: '#ECF1FE'
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  printButton: {
    backgroundColor: '#fff',
    color: '#000',
    borderRadius: '8px',
    borderColor: '#E5E5E5'
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  boxDependent: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#EEEEEE',
    borderRadius: '8px',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  },
  itemBoxRow: {
    width: '45%',
    marginBottom: '20px',
  }
}

const drawerWidth = 450;

function DrawerDetailNotification(props) {

  const [open, setOpen] = useState(true);
  const [name, setName] = useState(props['name'] || '');
  const [email, setEmail] = useState(props['email'] || '');
  const [checkin, setCheckin] = useState(props['checkin'] || '');
  const [checkout, setCheckout] = useState(props['checkout'] || '');
  const [phone, setPhone] = useState(props['phone'] || '');
  const [room, setRoom] = useState(props['room'] || '');
  const [typeRoom, setTypeRoom] = useState(props['type_room'] || '');
  const [token, setToken] = useState(props['token'] || '');
  const [dependents, setDependents] = useState(props['dependents'] || []);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    props.close()
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save()
  };

  const save = () => {

    const guest = {
      hotel_uuid: localStorage.getItem(HOTEL_UUID),
      reservation_code: token,
      room: room,
    }

    editPrecheckin(guest)
      .then(() => {
        toast.success("Hospede atualizado com sucesso!")
      })
      .catch(() => {
        toast.error("Erro ao atualizar hospede. Tente novamente mais tarde!")
      })

    props.close()
  }

  const Status = {
    ACTIVE: "ACTIVE", // já está no hotel
    CONFIRMED: "CONFIRMED", // ja fex precheckin
    WAITING: "WAITING", // agauradando precheckin
    EXPIRED: "EXPIRED",
    CANCELED: "CANCELED",
  }

  const renderStatus = (status) => {
    let text = ''
    let color = ''

    switch (status) {
      case Status.ACTIVE:
        text = "Ativo"
        color = "#007942"
        break
      case Status.CONFIRMED:
        text = "Confirmado"
        color = "#4177F6"
        break
      case Status.WAITING:
        text = "Aguardando"
        color = "#F09000"
        break
      case Status.EXPIRED:
        text = "Expirado"
        color = "#999999"
        break
      case Status.CANCELED:
        text = "Cancelado"
        color = "#FF3A44"
        break
      default:
        text = "Indefinido"
        color = "#0079"
    }

    return (
      <div style={{ display: "flex", paddingLeft: 12, paddingRight: 12, paddingTop: 5, paddingBottom: 5, backgroundColor: color, borderRadius: 100, alignItems: "center", justifyContent: "center" }}>
        <span style={{ textAlign: "center", fontSize: 11, lineHeight: "13px", color: "#FFFFFF", fontWeight: "700", alignItems: "center", justifyContent: "center" }}>
          {text.toUpperCase()}
        </span>
      </div>
    )
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: '1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Hóspede</span>
      </div>

      <div style={{ marginTop: 30 }}>

        <div style={styles.rowBetweenHeader}>
          <Button
            startIcon={<PrintIcon />}
            variant='outlined'
            style={styles.printButton}
            disabled={props.status === Status.CANCELED || props.status === Status.EXPIRED}
            sx={{ opacity: props.status === Status.CONFIRMED ? 1 : 0.5 }}
            onClick={props.getPDF}
          >
            Imprimir ficha
          </Button>
          {renderStatus(props.status)}
        </div>

        <form onSubmit={handleSubmit}>

          <div style={styles.box}>
            <span style={styles.subtitle}>Informações</span>

            <TextField
              label="Nome"
              variant="standard"
              style={styles.itemBox}
              value={name}
              onChange={(e) => setName(e.target.value)}
              disabled
            />

            <TextField
              label="Código acesso"
              variant="standard"
              style={styles.itemBox}
              value={token}
              onChange={(e) => setToken(e.target.value)}
              disabled
            />

            <span style={styles.subtitle}>Período</span>

            <div style={styles.rowBetween}>
              <TextField
                label="De"
                variant="standard"
                style={styles.itemBoxRow}
                value={convertStringToDatePTBR(checkin)}
                onChange={(e) => setCheckin(e.target.value)}
                disabled
              />
              <TextField
                label="Até"
                variant="standard"
                style={styles.itemBoxRow}
                value={convertStringToDatePTBR(checkout)}
                onChange={(e) => setCheckout(e.target.value)}
                disabled
              />
            </div>

            <span style={styles.subtitle}>Contatos</span>

            <TextField
              label="E-mail"
              variant="standard"
              style={styles.itemBox}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
            <TextField
              label="Telefone"
              variant="standard"
              style={styles.itemBox}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              disabled={props.status === Status.CANCELED || props.status === Status.EXPIRED}
            />

            <span style={styles.subtitle}>Estada</span>

            <TextField
              label="Tipo do quarto"
              variant="standard"
              style={styles.itemBox}
              value={typeRoom}
              onChange={(e) => setTypeRoom(e.target.value)}
              disabled
            />
            <TextField
              label="Quarto"
              variant="standard"
              style={styles.itemBox}
              value={room}
              onChange={(e) => setRoom(e.target.value)}
              disabled={props.status === Status.CANCELED || props.status === Status.EXPIRED}
            />

            <span style={styles.subtitle}>Dependentes/Acompanhantes</span>

            {dependents.length == 0 &&
              <div style={styles.boxDependent}>
                <span style={styles.subtitle}>Sem dependentes</span>
              </div>
            }

            {dependents &&
              dependents
                .map((dependent) => {
                  return (

                    <Accordion variant='outlined'>
                      <AccordionSummary id="panel-header" aria-controls="panel-content" expandIcon={<ExpandMoreIcon />}
                        sx={{ bgcolor: '#EEEEEEEE', borderWidth: 0, }}
                      >
                        <span style={styles.dependentName}>{dependent.name + " " + dependent.lastName}</span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <TextField
                          label="Nome"
                          variant="standard"
                          style={styles.itemBox}
                          value={dependent.name + " " + dependent.lastName}
                          disabled
                        />
                        <TextField
                          label="E-mail"
                          variant="standard"
                          style={styles.itemBox}
                          value={dependent.email}
                          disabled
                        />
                        <TextField
                          label="Telefone"
                          variant="standard"
                          style={styles.itemBox}
                          value={dependent.phone}
                          disabled
                        />
                      </AccordionDetails>
                    </Accordion>



                    // <div style={styles.boxDependent}>
                    //   <TextField
                    //     label="Nome"
                    //     variant="standard"
                    //     style={styles.itemBox}
                    //     value={dependent.name + " " + dependent.lastName}
                    //     disabled
                    //   />
                    //   <TextField
                    //     label="E-mail"
                    //     variant="standard"
                    //     style={styles.itemBox}
                    //     value={dependent.email}
                    //     disabled
                    //   />
                    // </div>
                  );
                })
            }

            <Button
              variant='contained'
              elevation={0}
              style={{ marginTop: '20px' }}
              type="submit"
            >
              Salvar hóspede
            </Button>
          </div>
        </form>
      </div >
    </SwipeableDrawer >
  )
}

export default DrawerDetailNotification;