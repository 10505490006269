import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextStyle from "../styles/text.style";

export default function Title(props) {
  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        {props.icon}
        <Typography variant="h5" style={TextStyle.title}>
          {props.text}
        </Typography>
      </Stack>
    </>
  );
}
