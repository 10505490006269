import React from 'react';
import { Box, Avatar, Button, Typography  } from '@mui/material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';
import TextStyle from '../../styles/text.style';
import ButtonStyle from '../../styles/button.style';

const InputImage = styled(Button)({
  textTransform: 'none'
});

function InputFileImage (props) {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  
  const [icon, setIcon] = React.useState(props.icon);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    const fileUploaded = event.target.files[0];
  
    var reader = new FileReader();
    reader.onload = e => {
      setIcon(e.target.result)
      props.setIcon(e.target.result);
    }
    reader.readAsDataURL(fileUploaded);
    props.setFile(fileUploaded);
  };

  return (
    <Box
      sx={{ 
        border: '1px solid #C4C4C4',
        backgroundColor: '#EEF1F5',
        borderRadius: '4px',
        p: 2,
      }}
      > 
      <Box
        sx={{ 
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      > 
        <InputImage
          variant="contained"
          style={ButtonStyle.uploadPhoto}
          onClick={handleClick}
          startIcon={<FileUploadIcon />}
        >
          Selecionar imagem 
        </InputImage>

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{display: 'none'}}
        />

        <Typography
          component="span"
          sx={TextStyle.label}
        >
          Tamanho padrão 800px x 800px
        </Typography>
        
        <Avatar 
          alt="Comida" 
          variant="square"
          src={icon}/>

      </Box>
      
      {props.children ? props.children : null}
    </Box>
  )
}

export default InputFileImage