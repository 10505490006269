
export default {
    styleRow: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#252733',
        fontWeight: '600',
    },

    statusText: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 11,
        alignSelf: 'center'
    },

    emptyStateContainer: {
        marginTop: 100
    },

    styleButton: {
        textTransform: 'none',
        backgroundColor: '#ECF1FE',
        color: '#4177F6',
        boxShadow: '0px 0px 0px 0px'
    }

}

