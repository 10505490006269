import React, { useState, useEffect } from "react";
import ActivitiesScreen from "./Activities.screen";
import { HOTEL_UUID } from '../../utils/constants';

import { getActivities } from '../../api/activitiesController'

export default function ActivitiesContainer() {
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([])

  useEffect(() => {
    loadActivities()
  }, []);

  async function loadActivities() {
    setLoading(true);

    await getActivities(localStorage.getItem(HOTEL_UUID))
      .then((result) => {

        const atividades = []

        atividades.push({ id: 'mon', day_of_week: "Segunda-feira", events: result.data.events.mon || [] })
        atividades.push({ id: 'tue', day_of_week: "Terça-feira", events: result.data.events.tue || [] })
        atividades.push({ id: 'wed', day_of_week: "Quarta-feira", events: result.data.events.wed || [] })
        atividades.push({ id: 'thu', day_of_week: "Quinta-feira", events: result.data.events.thu || [] })
        atividades.push({ id: 'fri', day_of_week: "Sexta-feira", events: result.data.events.fri || [] })
        atividades.push({ id: 'sat', day_of_week: "Sábado", events: result.data.events.sat || [] })
        atividades.push({ id: 'sun', day_of_week: "Domingo", events: result.data.events.sun || [] })

        setActivities(atividades)
        setLoading(false)
      })
  }

  return (
    <ActivitiesScreen
      loading={loading}
      activities={activities}
      reload={loadActivities}
    />
  )
}
