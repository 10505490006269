// Cardápio do hotel
import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import FastfoodIcon from '@mui/icons-material/Fastfood';

import Title from '../../components/Title'
import CardCounter from '../../components/CardCounter';
import { TODOS } from '../../utils/constants';

import TabNavMenu from '../../components/TabNavMenu';

function MenuDashboardScreen(props) {

  const [search, setSearch] = React.useState('')
  const [status, setStatus] = React.useState(TODOS)
  const [categoryId, setCategoryId] = React.useState(TODOS)
  const [menuPlaceId, setMenuPlaceId] = React.useState(TODOS)
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    setItems(props.rows)
  }, [props.rows])

  useEffect(() => {
    let rows = props.rows
    if (categoryId !== TODOS) {
      rows = rows.filter(i => i.category_id === categoryId)
    }

    if (menuPlaceId !== TODOS) {
      rows = rows.filter(i => i.menu_place_id === menuPlaceId)
    }

    if (status !== TODOS) {
      if (status === 'ATIVO') {
        rows = rows.filter(i => i['active'])
      } else {
        rows = rows.filter(i => !i['active'])
      }
    }

    if (!!search) {
      rows = rows.filter(i => i.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
        i.description.toLowerCase().indexOf(search.toLowerCase()) !== -1)
    }

    setItems(rows)
  }, [search, status, categoryId, menuPlaceId])

  const countProductActive = () => {
    return props.rows.filter(i => i['active']).length
  }

  const countProductInactive = () => {
    return props.rows.filter(i => !i['active']).length
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: '#FFFFFF',
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Title text="Cardápio" icon={<FastfoodIcon color='primary' />} />

        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <CardCounter count={props.rows.length} label="Produtos postados" loading={props.loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardCounter count={countProductActive()} label="Produtos ativos" loading={props.loading} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CardCounter count={countProductInactive()} label="Produtos inativos" loading={props.loading} />
          </Grid>
        </Grid>

        <TabNavMenu hotel={props.hotel} />

      </Container>
    </Box>
  )
}

export default MenuDashboardScreen;