import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Tab, Tabs } from '@mui/material';

import Orders from '../screens/orders/Orders.container'
import Menu from '../screens/menu/Menu.container';
import OrderHistory from '../screens/orders/OrderHistory';

import { useHotel } from "../contexts/HotelProvider";

function TabNavMenu(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabNavMenu.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export default function TabNav(props) {

  const [value, setValue] = React.useState(0);
  const { hotel } = useHotel();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', marginTop: 5 }}>
      <>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          aria-label="primary tabs example"
          style={{
            borderBottom: '1px solid #DFE0EB',
            marginLeft: 30
          }}
        >

          {hotel.features.menu_order &&
            <Tab label='Pedidos' {...a11yProps(0)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 200 }} />
          }

          <Tab label="Produtos" {...a11yProps(1)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 200, marginLeft: 50, marginRight: 50 }} />

          {hotel.features.menu_order &&
            <Tab label="Histórico de pedidos" {...a11yProps(2)} style={{ textTransform: 'none', fontSize: 20, fontWeight: 'bold', width: 300 }} />
          }
        </Tabs>

        {hotel.features.menu_order &&
          <TabNavMenu style={{ marginRight: -20 }} value={value} index={0}>
            <Orders />
          </TabNavMenu>
        }

        <TabNavMenu style={{ marginRight: -20 }} value={value} index={hotel.features.menu_order ? 1 : 0}>
          <Menu />
        </TabNavMenu>

        {hotel.features.menu_order &&
          <TabNavMenu style={{ marginRight: -20 }} value={value} index={2}>
            <OrderHistory />
          </TabNavMenu>
        }
      </>
    </Box>
  );
}