import axios from 'axios'

export async function updateRequest(hotelUID, item) {
  return axios.put(`/api/v1/requests`,
    {
      hotel_uuid: hotelUID,
      item: item
    }
  )
}

export async function paginationRequests(hotelUID, lastDoc, rowsPerPage, next, active) {
  return axios.post(`/api/v1/requests/${hotelUID}/pagination`,
    {
      item: lastDoc,
      rows_per_page: rowsPerPage,
      next: next,
      active: active
    }
  )
}