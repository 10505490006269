import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextStyle from '../styles/text.style';

export default function SubTitle (props) {
  return (
    <>
    <Stack direction="row" sx={{ mt: 1, mb: 1}}>
      <Typography variant="h5" style={TextStyle.subTitle}>
        {props.text}
      </Typography>
    </Stack>
    </>
  );
}

