import React, { PureComponent } from 'react';
import { BarChart, Bar, ReferenceArea, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default class StackedBarChartScreen extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          data={this.props.data}
          margin={{
            top: -30,
          }}
        >
          <Legend verticalAlign="top" height={100} align="right" />
          <CartesianGrid strokeDasharray="0" vertical={false} stroke={'#EBEDF0'} />
          <XAxis dataKey="name" style={{ textTransform: 'uppercase', fill: '#9FA2B4', fontSize: 12 }} axisLine={false} tickSize={0} tickMargin={10} />
          <YAxis style={{ fill: '#9FA2B4', fontSize: 12 }} axisLine={false} tickSize={0} tickMargin={10} orientation={'right'} />
          <Tooltip cursor={false} labelStyle={{ textTransform: 'uppercase' }} shared={false} />
          <Bar dataKey="detractors" name="Detratores" stackId="a" fill="#DFE0EB" />
          <Bar dataKey="neutral" name="Neutros" stackId="a" fill="#55CFFF" />
          <Bar dataKey="promoters" name="Promotores" stackId="a" fill="#3751FF" />

          <ReferenceArea x1={150} x2={180} y1={200} y2={300} stroke="red" strokeOpacity={0.3} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}