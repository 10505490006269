import * as React from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Badge } from "@mui/material";

const MenuItemNav = ({ to, title, icon, count }) => {
  let navigate = useNavigate();
  const { pathname } = useLocation();
  const selected = !!matchPath({ path: to, exact: true }, pathname);
  return (
    <ListItemButton
      style={{ marginTop: 10 }}
      selected={selected}
      onClick={() => {
        navigate(to);
      }}
    >
      <ListItemIcon>
        <Badge badgeContent={count} variant="dot" color="primary">
          {icon}
        </Badge>
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItemButton>
  );
};

export default MenuItemNav;
