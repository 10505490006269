import React, {
  createContext,
  useContext,
  useState,
  useMemo
} from "react";
import { USER_DATA_KEY } from "../utils/constants";
import * as userController from "../api/userController";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  let count = 0;

  async function getUserData() {
    if(loadingUser) {
      return
    }

    if(count > 0) {
      return
    }
    count = count + 1
    
    return new Promise((resolve, reject) => {
      if(user) {
        resolve(user)
        return
      }

      userController.getUserData()
        .then(async (response) => {
          const userData = response.data;
          localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
          setUser(userData);
          setLoadingUser(false);
          count = 0
          resolve(userData);
        })
        .catch(() => {
          setLoadingUser(false);
          count = 0
          reject();
        });
    })
  }

  const theValues = useMemo(() => ({
    user,
    loadingUser,
    getUserData
  }));

  return (
    <UserContext.Provider value={theValues}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
