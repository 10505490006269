import React, { useState, useEffect } from "react";
import ServicesScreen from "./Services.screen";
import { HOTEL_UUID } from '../../utils/constants';

import { getServices } from "../../api/servicesController";

export default function ServicesContainer() {
  const [loading, setLoading] = useState(true);
  const [services, setServices] = useState([])
  const [servicesTimes, setServicesTimes] = useState([])
  const [servicesOthers, setServicesOthers] = useState([])

  useEffect(() => {
    loadActivities()
  }, []);

  async function loadActivities() {
    setLoading(true);

    await getServices(localStorage.getItem(HOTEL_UUID))
      .then((result) => {
        setServices(result.data.services.times)
        setLoading(false)
      })
  }

  return (
    <ServicesScreen
      loading={loading}
      services={services}
      reload={loadActivities}
    />
  )
}
