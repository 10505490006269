import React, { useState, useEffect } from "react";
import PartnersScreen from "./Partners.screen";
import { HOTEL_UUID } from '../../utils/constants';

import { getPartners, getCountPrincipalPartners } from "../../api/partnersController";

export default function PartnersContainer() {
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([])
  const [countPrincipalPartners, setCountPrincipalPartners] = useState(0);

  useEffect(() => {
    loadPartners()
  }, []);

  async function loadPartners() {
    setLoading(true);
    // change to Partners
    await getPartners(localStorage.getItem(HOTEL_UUID))
      .then((result) => {
        setPartners(result.data)
        setLoading(false)
      })
    await getCountPrincipalPartners(localStorage.getItem(HOTEL_UUID))
      .then((result) => {
        setCountPrincipalPartners(result.data.count)
      })
  }

  return (
    <PartnersScreen
      loading={loading}
      partners={partners}
      reload={loadPartners}
      countPrincipalPartners={countPrincipalPartners}
    />
  )
}
