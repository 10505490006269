import React, { useState } from "react";
import { SwipeableDrawer, IconButton, TextField, Divider, MenuItem, Select } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubTitle from "../../components/SubTitle";
import { HOTEL_UUID } from "../../utils/constants";
import { createUpdateActivity, deleteActivity } from "../../api/activitiesController";
import { toast } from "react-toastify";
import ButtonLoading from "../../components/layout/ButtonLoading";
import DialogConfirm from "../../components/DialogConfirm";

const DANCE = require('../../assets/dance.png')
const GAME = require('../../assets/game.png')
const FOOD = require('../../assets/food.png')
const RELAX = require('../../assets/relax.png')

const styles = {
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    color: "#252733",
    letterSpacing: "0.4px",
    lineHeight: "24px",
  },
  local: {
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
  },
  date: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.2px",
    color: "#000000",
    opacity: "0.54",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#E8EAEE",
    borderRadius: "8px",
  },
  buttonClose: {
    background: "#C4C4C4",
    borderRadius: "6px",
    height: "32px",
    width: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "10px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    padding: "12px",
  },
  rowBetween: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  box: {
    padding: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  padding: {
    padding: "12px",
  },
  itemBox: {
    width: "100%",
    marginBottom: "20px",
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
  },

  data: {
    fontSize: 14,
    fontWeight: 700,
  },
  boxSugestionSulution: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "16px",
    width: "100%",
    backgroundColor: "#F5F5F5",
    borderRadius: "8px",
    marginTop: 1,
  },
  dateContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "10px",
  },
};

const drawerWidth = 600;

function DrawerActivities(props) {
  const [open, setOpen] = useState(true);
  const [title, setTitle] = useState(props.title || "");
  const [value, setValue] = useState(props.value || "");
  const [description, setDescription] = useState(props.description || "");
  const [activityId, setActivityId] = useState(props.id || null);
  const [day, setDay] = useState(props.dayOfWeek || "");
  const [dayId, setDayId] = useState(props.dayOfWeekId || "");
  const [type, setType] = useState(props.type || "");
  const [startTime, setStartTime] = useState(props.startTime || "00:00");

  const [loadingButton, setLoadingButton] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpen(open);
    props.close();
  };

  const daysWeekType = {
    mon: 'Segunda-feira',
    tue: 'Terça-feira',
    wed: 'Quarta-feira',
    thu: 'Quinta-feira',
    fri: 'Sexta-feira',
    sat: 'Sábado',
    sun: 'Domingo'
  }

  const save = async () => {
    setLoadingButton(true);

    if (day == "" || type == "") {
      setLoadingButton(false);
      return
    }

    let payload = {
      title: title,
      description: description,
      dayOfWeek: daysWeekType[dayId],
      dayOfWeekId: dayId,
      type: type,
      id: activityId,
      startTime: startTime,
      isActive: true
    };

    await createUpdateActivity(localStorage.getItem(HOTEL_UUID), payload)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
        setLoadingButton(false);
      })
  };

  const deleteItem = (responseOk) => {
    setOpenDelete(false);
    if (!responseOk) {
      return;
    }
    setLoadingButton(true);

    deleteActivity(localStorage.getItem(HOTEL_UUID), activityId, dayId)
      .then(() => {
        toast.success("Operação realizada com sucesso");
        setLoadingButton(false);
        props.close(true);
      })
      .catch(() => {
        toast.error("Erro, tente novamente mais tarde!");
        setLoadingButton(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    save();
  };

  const renderIcon = () => {
    if (type && type == 'DANCE') {
      return <img src={DANCE} height={26} width={26} alt="Fives" />
    }
    if (type && type == 'RELAX') {
      return <img src={RELAX} height={26} width={26} alt="Fives" />
    }
    if (type && type == 'FOOD') {
      return <img src={FOOD} height={26} width={26} alt="Fives" />
    }
    if (type && type == 'GAME') {
      return <img src={GAME} height={26} width={26} alt="Fives" />
    }
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: "1300",
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton component="span" onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Nova atividade</span>
      </div>
      <Divider />

      <form onSubmit={handleSubmit}>
        <div style={styles.box}>
          <SubTitle text="Informações da atividade" variant="subtitle2" />
          <div style={styles.boxAddPhone}>
            <TextField
              id="outlined-basic"
              label="Título"
              variant="outlined"
              placeholder="Nome do serviço"
              required
              style={styles.itemBox}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />

            <TextField
              id="outlined-basic"
              label="Descrição"
              variant="outlined"
              placeholder="descrição"
              required
              multiline
              rows={4}
              style={styles.itemBox}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />

            <div style={styles.dateContainer}>
              <TextField
                id="outlined-basic"
                label="Hora de início"
                variant="outlined"
                type="time"
                required
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                sx={{ width: "15ch" }}
              />
              <Select
                sx={{ width: '35%' }}
                value={dayId}
                onChange={(day) => {
                  setDayId(day.target.value)
                  setDay(day.target.labels)
                }}
                displayEmpty
              >
                <MenuItem value=""><em>Dia da semana</em></MenuItem>
                <MenuItem value={'mon'} >Segunda-Feira</MenuItem>
                <MenuItem value={'tue'}>Terça-feira</MenuItem>
                <MenuItem value={'wed'}>Quarta-feira</MenuItem>
                <MenuItem value={'thu'}>Quinta-feira</MenuItem>
                <MenuItem value={'fri'}>Sexta-Feira</MenuItem>
                <MenuItem value={'sat'}>Sábado</MenuItem>
                <MenuItem value={'sun'}>Domingo</MenuItem>
              </Select>

              <Select
                sx={{ width: '35%' }}
                value={type}
                onChange={(type) => setType(type.target.value)}
                displayEmpty
              >
                <MenuItem value=""><em>Tipo</em></MenuItem>
                <MenuItem value={'FOOD'}>Comida</MenuItem>
                <MenuItem value={'DANCE'}>Dança</MenuItem>
                <MenuItem value={'GAME'}>Jogo</MenuItem>
                <MenuItem value={'RELAX'}>Relaxamento</MenuItem>
              </Select>
            </div>

            <ButtonLoading label="Salvar" loading={loadingButton} />

            {props.id ? (
              <ButtonLoading
                label="Excluir"
                loading={loadingButton}
                onClick={() => setOpenDelete(true)}
                delete
              />
            ) : null}

          </div>
        </div>
      </form>

      {openDelete && (
        <DialogConfirm
          onResponse={deleteItem}
          entity="essa atividade"
        />
      )}

      <div style={styles.box}>
        <SubTitle text="Como o hóspede visualiza:" variant="subtitle2" />

        <div style={{ marginTop: 40 }}>

          {/* header */}
          <div style={{ display: "flex" }}>

            <div style={{
              position: "absolute",
              width: 2, backgroundColor: '#4177F6', height: 70, marginTop: 70, left: 60
            }}>
            </div>

            {/* icon */}
            <div style={{ zIndex: 999, display: 'flex', alignItems: 'center', justifyContent: 'center', height: 75, width: 75, borderRadius: 8, backgroundColor: '#E8EAEE' }}>
              {renderIcon()}
            </div>

            <div style={{
              position: "absolute",
              width: 2, backgroundColor: '#4177F6', height: 40, marginTop: -40, left: 60
            }}>
            </div>


            {/* title e hora */}
            <div style={{ padding: 8, flex: 1, }}>
              <div>
                <span style={{
                  fontSize: 16,
                  fontWeight: 600,
                }}>{title}</span>
              </div>

              {/* hora */}
              <div style={{
                display: 'inline-flex',
                backgroundColor: '#4177F6', borderRadius: 4,
                alignItems: "center", justifyContent: 'center',
                paddingLeft: 6, paddingRight: 6, marginTop: 5, paddingTop: 1, paddingBottom: 1
              }}>
                <span style={{ color: '#fff', fontSize: 13 }}>{startTime}</span>
              </div>

              {/* descrição */}
              <div style={{ maxWidth: '80%', marginTop: 12 }}>
                <span style={{
                  fontSize: 14,
                  fontWeight: 500,
                  opacity: 0.5400000214576721
                }}>{description}</span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </SwipeableDrawer>
  );
}

export default DrawerActivities;
