import React, { useState } from 'react';
import { SwipeableDrawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BoxItemNotifications from './BoxItemNotifications';

const styles = {
  title: {
    fontWeight: '600',
    fontSize: '19px',
    lineHeight: '28px',
    letterSpacing: '0.4px',
    color: '#252733'
  },
  subTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '0.1px',
    color: '#252733',
    opacity: '0.5'
  },
  textSuggestion: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#000000',
    opacity: '0.5'
  },
  buttonClose: {
    background: '#C4C4C4',
    borderRadius: '6px',
    height: '32px',
    width: '32px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px',
  },
  rowBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '12px',
  },
  box: {
    padding: '20px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  padding: {
    padding: '12px',
  },
  itemBox: {
    width: '100%',
    marginBottom: '20px',
  }
}

const drawerWidth = 450;

function DrawerDetailNotification(props) {
  const [open, setOpen] = useState(true);
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
    props.close()
  };

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      sx={{
        zIndex: '1300',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
    >
      <div style={styles.row}>
        <div style={styles.buttonClose}>
          <IconButton
            component="span"
            onClick={toggleDrawer(false)}>
            <CloseIcon />
          </IconButton>
        </div>
        <span style={styles.title}>Notificação</span>
      </div>

      <div style={{ marginTop: 30 }}>
        <BoxItemNotifications {...props} />
      </div>
    </SwipeableDrawer>
  )
}

export default DrawerDetailNotification;