import React from "react";
import Dashboard from "../screens/dash/Dashboard.container";
import MenuDashboard from "../screens/menuDashboard/MenuDashboard.container";
import Request from "../screens/requests/TabRequests";
import Hotelinfo from "../screens/hotelinfo/Hotelinfo.container";
import Login from "../screens/Login";
import ForgotPassword from "../screens/ForgotPassword";
import NotFound from "../screens/NotFound";
import NotPermission from "../screens/NotPermission";
import ContainerMain from "../components/layout/ContainerMain";
import { USER_DATA_KEY } from "../utils/constants";
import Scheduler from "../screens/scheduler/Scheduler.container";
import Activities from "../screens/activities/Activities.container";
import Services from "../screens/services/Services.container";
import Partners from "../screens/partners/Partners.container";
import Guests from "../screens/guests/Guests.container";
import Signup from "../screens/signup/Signup.container";
import TermsOfUse from "../screens/TermsOfUse";
import PrivcacyPolice from "../screens/PrivacyPolice";
import SplashScreen from "../screens/SplashScreen";

import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import permissions from "../enums/permissions";
import { TOKEN_KEY } from "../App";
import { toast } from "react-toastify";

const MyRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/privacy-policy" element={<PrivcacyPolice />} />
      <Route
        path="/splashscreen"
        element={
          <RequireAuth public>
            <SplashScreen />
          </RequireAuth>
        }
      />

      <Route element={<ContainerMain />}>
        <Route
          path="/dashboard"
          element={
            <RequireAuth public>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/menu"
          element={
            <RequireAuth needPermission={permissions.MENU_PERMISSION}>
              <MenuDashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/requests"
          element={
            <RequireAuth needPermission={permissions.REQUEST_PERMISSION}>
              <Request />
            </RequireAuth>
          }
        />
        <Route
          path="/hotel"
          element={
            <RequireAuth needPermission={permissions.INFORMATIONS_PERMISSION}>
              <Hotelinfo />
            </RequireAuth>
          }
        />
        {/*
        <Route
          path="/chat"
          element={
            <RequireAuth needPermission={permissions.CHAT_PERMISSION}>
              <Chat />
            </RequireAuth>
          }
        />*/}
        <Route
          path="/scheduler"
          element={
            <RequireAuth needPermission={permissions.SCHEDULER}>
              <Scheduler />
            </RequireAuth>
          }
        />
        <Route
          path="/activities"
          element={
            <RequireAuth needPermission={permissions.ACTIVITY_CONTROLLER}>
              <Activities />
            </RequireAuth>
          }
        />
        <Route
          path="/services"
          element={
            <RequireAuth needPermission={permissions.SERVICES_CONTROLLER}>
              <Services />
            </RequireAuth>
          }
        />
        <Route
          path="/partners"
          element={
            <RequireAuth needPermission={permissions.SERVICES_CONTROLLER}>
              <Partners />
            </RequireAuth>
          }
        />
        <Route
          path="/guests"
          element={
            <RequireAuth needPermission={permissions.GUESTS_CONTROLLER}>
              <Guests />
            </RequireAuth>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
export default MyRoutes;

function RequireAuth(props) {
  let location = useLocation();
  var permissions = [];
  const token = localStorage.getItem(TOKEN_KEY);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const tokenData = parseJwt(token);

  if (tokenData.exp * 1000 < Date.now()) {
    toast.error("Sua sessão expirou, faça o login novamente");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (props.public) {
    return props.children
  }

  const userData = localStorage.getItem(USER_DATA_KEY);
  const userDataParsed = JSON.parse(userData);

  userDataParsed.permissions.forEach((element) => {
    permissions.push(element);
  });

  if (!permissions.includes(props.needPermission)) {
    return <NotPermission state={{ from: location }} replace />;
  }

  return props.children;
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
