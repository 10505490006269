import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import SubTitle from "../../components/SubTitle";
import PartnerIcon from "@mui/icons-material/BusinessCenter";

import DrawerPartner from "./DrawerPartners";
import Title from "../../components/Title";
import GridPartners from "./GridPartners";
import CardCounter from "../../components/CardCounter";

const AddButton = styled(Button)({
  textTransform: "none",
  fontWeight: "bold",
});

function PartnersScreen({ loading, partners, reload, countPrincipalPartners }) {
  const [openDrawerPartner, setOpenDrawerPartner] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState(null);

  useEffect(() => {
    reload();
  }, []);

  const close = (is_reload) => {
    setOpenDrawerPartner(false);
    setItemSelected(null);

    if (is_reload) {
      reload();
    }
  };

  const openEdit = (item) => {
    setItemSelected(item);
    setOpenDrawerPartner(true);
  };

  const getCountPartners = (active) => {
    let count = 0;
    partners.forEach((partner) => {
      if (partner.active === active) {
        count++;
      }
    });
    return count;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
        <Grid container sx={{ mt: 2, mb: 4 }} spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={10}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Title text="Parceiros" icon={<PartnerIcon color="primary" />} />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={2}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <AddButton
              variant="contained"
              color="primary"
              onClick={() => setOpenDrawerPartner(true)}
              startIcon={<AddIcon />}
            >
              Adicionar parceiro
            </AddButton>
          </Grid>
        </Grid>

        <SubTitle
          text={`Cadastre e gerencie todos os seus parceiros.`}
        />
        <SubTitle
          text={`Ex.: Restaurantes, passeios, transportes...`}
        />

        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <CardCounter count={getCountPartners(true)} label="Parceiros ativos" loading={false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardCounter count={getCountPartners(false)} label="Parceiros inativos" loading={false} />
          </Grid>
        </Grid>

        <GridPartners rows={partners} loading={loading} openEdit={openEdit} reload={reload}/>
      </Container>

      {openDrawerPartner ? (
        <DrawerPartner {...itemSelected} close={close} countPrincipalPartners={countPrincipalPartners}/>
      ) : null}
    </Box>
  );
}

export default PartnersScreen;
