import React from 'react';
import { Divider } from '@mui/material';
import OrderProgress from './OrdersProgress'

function OrderBoard(props) {

  return (

    <div style={{ minWidth: 470, backgroundColor: '#f8f9fa', borderTopLeftRadius: 8, borderTopRightRadius: 8, paddingLeft: 25 }}>

      <div style={{ padding: 15 }}>
        <span style={{ fontSize: 20, fontWeight: 700 }}>{props.title}</span>
        <p style={{ color: '#2F353D', fontSize: 14, fontWeight: 700 }}>Total: {props.count}</p>
      </div>

      <Divider style={{ backgroundColor: '#fff', height: 5, borderWidth: 0, marginBottom: 5, marginTop: -10, marginLeft: -30 }} />

      {props.orders.map((order, index) => {
        return (
          <OrderProgress order={order} />
        )
      })}
    </div>
  )
}

export default OrderBoard;