import React, { useState, useEffect } from "react";
import TabItemRequest from "./TabItemRequest";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import RequestIcon from "@mui/icons-material/ConfirmationNumber";
import Title from "../../components/Title";
import CardCounter from "../../components/CardCounter";
import Grid from "@mui/material/Grid";
import Loading from "../../components/Loading";
import { useNotification } from "../../contexts/NotificationProvider";

function TabRequests(props) {
  const [reloading, setReloading] = useState(false);

  const [active, setActive] = React.useState(0);
  const [notActive, setNotActive] = React.useState(0);
  const { resetRequest } = useNotification();

  useEffect(() => {
    resetRequest();
  }, []);

  const reload = () => {
    setReloading(true);
    setTimeout(() => {
      setReloading(false);
    }, 1000);
  };

  function conterActive(n) {
    setActive(n);
  }

  function conterNotActive(n) {
    setNotActive(n);
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "#FFFFFF",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
      }}
    >
      {reloading ? (
        <Loading color={"#4177F6"} text={"Carregando..."} />
      ) : (
        <Container maxWidth="xl" sx={{ mt: 13, mb: 4 }}>
          <Title
            text="Chamados"
            icon={<RequestIcon color="primary" style={{ marginTop: 4 }} />}
          />

          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <CardCounter
                count={active}
                label="Chamados abertos"
                loading={props.loading}
              />
            </Grid>
            <Grid item xs={16} md={6}>
              <CardCounter
                count={notActive}
                label="Chamados Finalizados"
                loading={props.loading}
              />
            </Grid>
          </Grid>

          <TabItemRequest
            active={true}
            conterActive={conterActive}
            hotel={props.hotel}
            reload={reload}
          />
          <TabItemRequest
            active={false}
            conterNotActive={conterNotActive}
            hotel={props.hotel}
            reload={reload}
          />
        </Container>
      )}
    </Box>
  );
}

export default TabRequests;
